/**
 * Load custom script asynchronously
 *
 * @export
 * @param {string} src URL for the third-party library being loaded.
 * @param {string} [id] e.g., googleMaps or stripe
 */
export async function loadDynamicScript ( src, id ) {
  return new Promise( resolve => {
    const existingScript = document.getElementById( id )

    if ( !existingScript ) {
      const script = document.createElement( 'script' )
      script.src = src
      if ( id ) script.id = id
      document.body.appendChild( script )
      script.onload = () => {
        resolve( true )
      }
    }
    else {
      resolve( true )
    }
  } )
}

/**
 * Appends image bottom
 *
 * @export
 * @param {string} src
 * @returns
 */
export async function loadDynamicImg ( src ) {
  return new Promise( resolve => {
    const img = document.createElement( 'img' )
    img.src = src

    img.style.position = 'absolute'
    img.style.bottom = 0
    img.style.left = 0

    document.body.appendChild( img )
  } )
}
