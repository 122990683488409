import { parse } from 'querystring'
import type { Commit, Dispatch } from 'vuex/types/index'
import _ from 'lodash'
import type { Context } from '@nuxt/types'
import type { RootState } from '~/store/index'
import isDuplicate from '~/utils/Application/isDuplicate'

/**
 * Check if duplicate and store unemployed redaction when set
 *
 * @param {object}   state    Vuex State object
 * @param {function} commit   Vuex commit function
 * @param {function} dispatch Vuex dispatch function
 * @param {context}  context  Nuxt Context
 */
export default async function (
  state: RootState,
  commit: Commit,
  dispatch: Dispatch,
  { req, redirect, app, route }: Context
) {
  return await new Promise<void>( function ( resolve ) {
    // Only run logic if post
    if ( req.method === 'POST' ) {
      let body = ''
      req.on( 'data', ( chunk ) => {
        body += chunk.toString()
      } )
      req.on( 'end', async () => {
        const parsedBody = parse( body )
        let duplicateCheck
        // Check if duplicate
        if ( parsedBody.data_redirect ) {
          duplicateCheck = await isDuplicate( parsedBody, app.$logger )
          // Redirect if duplicate and resolve promise
          if ( _.has( duplicateCheck, 'ActiveUntil' ) && duplicateCheck.ActiveUntil !== null ) {
            redirect( parsedBody.data_redirect as string )
            resolve()
          }
        }
        if ( parsedBody.data_rate ) {
          if ( !duplicateCheck ) {
            duplicateCheck = await isDuplicate( parsedBody, app.$logger )
          }
          // Redirect if duplicate and resolve promise
          if ( _.has( duplicateCheck, 'Rate' ) && duplicateCheck.Rate === 0 ) {
            redirect( parsedBody.data_rate as string )
            resolve()
          }
        }
        // Check if unemployed redirect
        if ( parsedBody.data_unemployed ) {
          // Only commit if whitelabel state is available
          if ( state.whitelabel ) {
            commit( 'whitelabel/UNEMPLOYED_REDIRECT', parsedBody.data_unemployed )
          }
          delete parsedBody.data_unemployed
        }
        // Merge application data from POST data
        await dispatch( 'loanApplication/merge', parsedBody )
        resolve()
      } )
    }
    // If not post
    else {
      // Merge application data from query params
      dispatch( 'loanApplication/merge', route.query )
      resolve()
    }
  } )
}
