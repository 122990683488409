/**
 * Replaces vue-router default parse function.
 *
 * @param {string} query Query to parse.
 */
export default function ( query ) {
  var res = {}

  // Decode or unescape query.
  var decode = ( query ) => {
    let decoded
    try {
      decoded = decodeURIComponent( query )
    }
    catch ( error ) {
      if ( error.message === 'URI malformed' ) {
        decoded = unescape( query )
      }
    }
    return decoded
  }

  // Some copy paste vue magic
  query = query.trim().replace( /^(\?|#|&)/, '' )

  if ( !query ) {
    return res
  }

  query.split( '&' ).forEach( function ( param ) {
    var parts = param.replace( /\+/g, ' ' ).split( '=' )
    var key = decode( parts.shift() )
    var val = parts.length > 0
      ? decode( parts.join( '=' ) )
      : null

    if ( res[key] === undefined ) {
      res[key] = val
    }
    else if ( Array.isArray( res[key] ) ) {
      res[key].push( val )
    }
    else {
      res[key] = [res[key], val]
    }
  } )

  return res
}
