<template>
  <div>
    <navbar />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application @sent="loadThankYouPixels" />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  layout: 'whitelabel',
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'Käyttöluotto.com -palvelun avulla voit kilpailuttaa käyttöluoton jopa 70 000 € asti. Tee ilmainen hakemus ja löydä sinulle edullisin käyttöluotto!'
    const theme = '#fc943a'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/xn--kyttluotto-q5a3t/favicon.png' ) }]
    }
  },
  data () {
    return {
      name: 'Käyttöluotto',
      domain: 'xn--kyttluotto-q5a3t.com'
    }
  },
  mounted () {
    // Do not load on development
    if ( this.$config.RELEASE_STAGE !== 'production' ) return
    // Load facebook pixel (Delete RL console first)
    delete window.fbq
    /* eslint-disable */
    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '688064651350462');
    fbq('track', 'PageView')
    fbq('track', 'Lead');
    fbq('track', 'Kayttoluottoliidi');
    // Load trackcmp pixel
    function getUrlVar(key) {
    var result = new RegExp(key + "=([^&]*)", "i").exec(window.location.search);
      return result && decodeURIComponent(result[1]) || "";
    }
    var email = getUrlVar('email');
    var trackcmp_email = (typeof(email) != "undefined") ? email : '';
    var trackcmp = document.createElement("script");
    trackcmp.async = true;
    trackcmp.type = 'text/javascript';
    trackcmp.src = '//trackcmp.net/visit?actid=65964362&e=' + encodeURIComponent(trackcmp_email) + '&r=' + encodeURIComponent(document.referrer) + '&u=' + encodeURIComponent(window.location.href);
    var trackcmp_s = document.getElementsByTagName("script");
    if (trackcmp_s.length) {
      trackcmp_s[0].parentNode.appendChild(trackcmp);
    } else {
      var trackcmp_h = document.getElementsByTagName("head");
      trackcmp_h.length && trackcmp_h[0].appendChild(trackcmp);
    }
    /* eslint-enable */
  },
  methods: {
    loadThankYouPixels ( formData ) {
      if ( this.$config.RELEASE_STAGE !== 'production' ) return
      /* eslint-disable */
      fbq('track', 'CompleteRegistration');
      fbq('track', 'RLfull');

      function acEnableConversionTracking () {
        var expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30);
        document.cookie = "ac_enable_tracking=1; expires= " + expiration + "; path=/";
        acTrackConversion();
      }
      function acTrackConversion () {
        var trackcmp_email = formData.email || '';
        var trackcmp_conversion = '2';
        var trackcmp_conversion_value = '';
        var trackcmp = document.createElement("script");
        trackcmp.async = true;
        trackcmp.type = 'text/javascript';
        trackcmp.src = '//trackcmp.net/convert?actid=65964362&e=(' + encodeURIComponent(trackcmp_email) + ')&c=' + trackcmp_conversion + '&v=' + trackcmp_conversion_value +
          '&r=' + encodeURIComponent(document.referrer) + '&u=' + encodeURIComponent(window.location.href);
        var trackcmp_s = document.getElementsByTagName("script");
        if (trackcmp_s.length) {
          trackcmp_s[0].parentNode.appendChild(trackcmp);
        } else {
          var trackcmp_h = document.getElementsByTagName("head");
          trackcmp_h.length && trackcmp_h[0].appendChild(trackcmp);
        }
      }
      acEnableConversionTracking();
      /* eslint-enable */
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #fc943a;
$wl-body-background: url(//www.xn--kyttluotto-q5a3t.com/wp-content/uploads/2018/10/yhdistelylainaa.jpg) no-repeat center center fixed;
$wl-body-overlay: rgba(10,0,0,.7);

$wl-navbar-background: #035e7b;
$wl-navbar-height: 3.8rem;
$wl-navbar-logo-height: 2rem;

$wl-slider-handle-border: 2px solid white;

$wl-footer-color: white;
$wl-footer-background: #035e7b;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.xn--kyttluotto-q5a3t {
  @include whitelabel;
}
</style>
