import { loadDynamicImg } from '../loader'

/**
 * Triggers ALehti pixel on applicant sent
 *
 * @export
 * @param {object} form - Form data.
 */
export function onApplicationSent ( _form ) {
  if ( this.app.$config.RELEASE_STAGE === 'production' ) {
    try {
      const cookie = this.app.$cookies.get( 'RL_arrive' )
      if ( cookie && ['RL_alehdet_rl', 'RL_alehdet'].includes( cookie ) ) {
        const axel = Math.random() + ''
        const a = axel * 10000000000000
        loadDynamicImg(
          `https://pubads.g.doubleclick.net/activity;xsp=4456282;ord=${a}?`
        )
      }
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
  }
  return false
}
