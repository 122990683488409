<template>
  <div class="app">
    <div class="app-section app-section_header">
      <p class="title is-4 has-text-rlgreen">Kiitos lainahakemuksesta</p>
      <div class="content">
        <p>Sinulla on jo aktiivinen hakemus Rahalaitoksella, pääset näkemään omat lainatarjouksesi tästä:</p>
        <a class="button is-primary" :href="redirectUrl">Katso omat lainatarjouksesi!</a>
      </div>
      <div class="content">
        <p>Jos haluat pyytää lisää lainatarjouksia muilta pankeilta ja rahoitusyhtiöiltä, voit pyytää niitä täältä:</p>
        <a class="button is-primary" href="https://www.vertaapankkeja.fi/vaihtoehto/">Pyydä lisää lainatarjouksia!</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    redirectUrl: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
