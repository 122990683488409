import Tracking from '~/utils/Tracking'

export default ( { app, route, store }, inject ) => {
  const tracking = new Tracking( app, route )
  // For some routes we do not want tracking.
  if (
    !store.state.whitelabel &&
    !route.path.startsWith( '/cc-new' )
  ) {

    // run tracking on consented-to platforms
    tracking.load( [
      Tracking.NECESSARY,
      Tracking.MARKETING,
      Tracking.STATISTICS,
      Tracking.PREFERENCES
    ] )

    // Init onPageView every time the route changes (fired on initialization too)
    app.router.afterEach( ( to, from ) => {
      tracking.onPageView( to, from )
    } )
  }
  else {
    tracking.disable()
  }

  // Inject tracking into vue app and context
  inject( 'tracking', tracking )
}
