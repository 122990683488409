<template>
  <div>
    <navbar logo="false" />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  layout: 'whitelabel',
  data () {
    return {
      name: 'Lainalaskuri',
      domain: 'lainalaskuri.com'
    }
  },
  head () {
    const desc = `Lainalaskuri auttaa sinua löytämään lainan useilta eri pankeilta ja rahoituslaitoksilta. Laske laina
      sinulle sopivalla kuukausierällä.`
    const theme = '#6610f2'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/lainalaskuri/favicon.png' ) }]
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #ff6c33;
$wl-secondary: #6610f2;

$wl-body-background: #f7f7f7;

$wl-navbar-background: linear-gradient(90deg,rgba(105,104,210,.95) 0%,rgba(70,70,112,.95) 100%);
$wl-navbar-item-color: white;

$wl-button-background: #ff6c33;
$wl-footer-background: #f7f7f7;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.lainalaskuri {
  @include whitelabel;

  .navbar-item {
    text-transform: uppercase;
  }

  form .app-section:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: linear-gradient(90deg,rgba(105,104,210,.95) 0%,rgba(70,70,112,.95) 100%);
    color: white;
    text-align: center;
  }
}
</style>
