<template>
  <div class="login-page">
    <navbar />
    <nuxt />
    <section v-if="$store.getters['auth/isLoggedIn']" class="section is-small has-background-white">
      <div class="container">
        <p>
          <strong>VASTUURAJOITUS:</strong> Rahalaitos.fi ei ota mitään vastuuta palvelun toimivuudesta, yhteistyökumppanien mahdollisesti tekemistä tarjouksista, tuotteista tai tuotteita koskevan sopimuksen syntymisestä. Luotonhakijalle myönnetty lainasumma saattaa poiketa lainanhakijan hakemasta lainasummasta.
        </p>
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp :show-links="false" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    navbar: () => import( '~/components/login/navbar/index' ),
    footerComp: () => import( '~/components/layout/footer' )
  },
  head () {
    const result = {
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi' + this.$route.path }
      ]
    }

    if ( ['www2.rahalaitos.fi', 'rahalaitos-v2.local'].includes( this.$domain ) ) {
      result.meta = [{ hid: 'robots', name: 'robots', content: 'noindex' }]
    }

    return result
  },
}
</script>

<style lang="scss">
  .login-page {
    background-image: url('~assets/img/hero-bg.jpg');
    background-size: cover;
    overflow: hidden;
    .box-title {
      font-weight: 600;
    }
  }
</style>
