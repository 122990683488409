<template>
  <div>
    <navbar />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" :content="footer" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  layout: 'whitelabel',
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'Smartraha on uusi netissä toimiva suomalainen palvelu, joka auttaa löytämään kulutusluottoja jopa 70 000 € asti.'
    const theme = '#e94c3d'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/smartraha/favicon.ico' ) }]
    }
  },
  data () {
    return {
      name: 'Smartraha',
      domain: 'smartraha.fi',
      footer: [
        {
          title: 'Smartraha.fi',
          content: [
            'SmartResponse ApS',
            'Vesterbrogade 149',
            '1620 Kööpenhamina',
            'Tanska',
            'Y-tunnus: DK25057325'
          ]
        },
        {
          title: 'Asiakaspalvelu',
          content: [
            'Asiakaspalvelumme palvelee vain sähköpostitse: asiakaspalvelu@smartraha.fi'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #e94c3d;

$wl-navbar-logo-height: 3rem;

$wl-body-background: #ebf4fc;

$wl-app-border-radius: 0;

$wl-submit-button-border-radius: 4px;

$wl-footer-color: #333333;
$wl-footer-background: #ebf4fc;
$wl-footer-link-title-color: #e94c3d;
$wl-footer-link-color: #333333;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.smartraha {
  @include whitelabel;
}
</style>
