<template>
  <div class="content">
    <breadcrumb v-if="breadcrumb && breadcrumb.length" :path="getBreadcrumb" />
    <div v-if="contentSlider" class="subpage_floating">
      <picture>
        <source
          v-for="(format, index) in image.formats"
          :key="index"
          :srcset="format.src"
          :media="`(max-width: ${format.width}px)`"
        >
        <!-- Get the largers size image available. Formats are sorted from smallest to largest -->
        <img
          :src="image.formats[ image.formats.length - 1 ].src"
          :alt="title"
        >
      </picture>
      <sliders class="is-hidden-touch" :business-loan="contentSliderIsBusiness" />
    </div>
    <picture v-else>
      <source
        v-for="(format, index) in image.formats"
        :key="index"
        :srcset="format.src"
        :media="`(max-width: ${format.width}px)`"
      >
      <!-- Get the largers size image available. Formats are sorted from smallest to largest -->
      <img
        :src="image.formats[ image.formats.length - 1 ].src"
        :alt="title"
        style="width: 100%; margin-bottom: 1em;"
      >
    </picture>
    <span v-html="data.top" />
    <template v-if="contentSlider">
      <sliders class="is-hidden-desktop" :business-loan="contentSliderIsBusiness" />
    </template>
    <span v-html="data.bottom" />
    <template v-if="sliders">
      <strapi :data="sliders" />
    </template>
    <div v-if="script" v-html="script" />
    <share :text="short" />
  </div>
</template>

<script>
export default {
  components: {
    breadcrumb: () => import( '~/components/breadcrumb' ),
    share: () => import( '~/components/subpage/share' ),
    sliders: () => import( '~/components/subpage/sliders' ),
    strapi: () => import( '~/components/strapi/sliders' )
  },
  layout: 'articles',
  async asyncData ( { app, params, route, error } ) {
    try {
      const path = route.matched[0].props.default.path
      const strapi = await app.$axios.$get( `/cms/${path}?slug=${params.strapi}` )
      if ( strapi.length ) {
        const article = strapi[0]
        const result = {
          image: {
            src: article.image ? `/cms${article.image.url}` : '',
            formats: []
          },
          short: article.title
        }
        // Parse image additional sizes
        const formats = []
        Object.keys( article.image.formats ).forEach( ( format ) => {
          const formatData = article.image.formats[format]
          formats.push( {
            width: formatData.width,
            src: `/cms${formatData.url}`
          } )
        } )
        formats.sort( ( a, b ) => ( a.width > b.width ) ? 1 : ( ( b.width > a.width ) ? -1 : 0 ) )
        result.image.formats = formats
        // Parse content for media and change them to use proxy
        article.content = article.content.replace( /"\/uploads\//g, '"/cms/uploads/' )
        // Check if we need to import slider
        let splitted = article.content.split( 'contentSlider()' )
        result.contentSlider = splitted.length >= 2
        // Try for business slider
        if ( !result.contentSlider ) {
          splitted = article.content.split( 'contentSlider(business)' )
          result.contentSlider = result.contentSliderIsBusiness = splitted.length >= 2
        }
        result.data = { top: splitted[0], bottom: splitted[1] }
        // Store meta tags
        result.title = article.title
        result.description = article.description
        // Get sliders
        result.sliders = article.Slider
        // Store script
        result.script = article.Script
        // Store breadrumbs
        result.breadcrumb = article.Breadcrumb
        return result
      }
      error( {
        statusCode: 404,
        message: 'Emme valitettavasti löytäneet hakemaanne sivua.'
      } )
    }
    catch ( e ) {
      app.$logger.error( e )
      error( {
        statusCode: 500,
        message: 'Sivun lataamisessa ilmeni ongelmia.'
      } )
    }
  },
  data () {
    return {
      image: {
        src: '',
        formats: []
      },
      data: {
        top: '',
        bottom: ''
      },
      contentSlider: false,
      contentSliderIsBusiness: false,
      title: '',
      description: '',
      sliders: null
    }
  },
  head () {
    return {
      title: this.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description
        }
      ]
    }
  },
  computed: {
    getBreadcrumb () {
      const result = {}
      this.breadcrumb.forEach( ( element ) => {
        result[element.Name] = element.Link
      } )
      return result
    }
  }
}
</script>
