<template>
  <div>
    <navbar :is-spaced="false">
      <div class="navbar-menu">
        <div class="navbar-end">
          <a class="navbar-item" href="https://www.stara.fi/">
            Takaisin staraan
          </a>
        </div>
      </div>
    </navbar>
    <nuxt-child :site-name="name" />
    <template v-if="$route.path === '/'">
      <section class="section is-small  has-background-light">
        <div class="container">
          <div class="content">
            <h1 class="title has-text-centered is-size-4-mobile">
              Lainaa jopa
              <span class="has-text-primary">70.000</span>
              euroa muutamassa minuutissa
            </h1>
            <h2 class="subtitle has-text-centered is-size-5-mobile">
              Kilpailuta maksutta jopa
              <span class="has-text-primary">25</span>
              lainantarjoajaa
            </h2>
            <p class="has-text-centered is-size-4">
              Lainakone on täysin kotimainen palvelu, joka etsii puolestasi sinulle parhaan lainan.
            </p>
          </div>
          <loan-application />
        </div>
      </section>
      <section class="section loan-limit">
        <div class="container">
          <div class="loan-limit_container">
            <div class="loan-limit_el">
              Olet vähintään 20-vuotias
            </div>
            <div class="loan-limit_el">
              Säännölliset palkka- tai eläketulot
            </div>
            <div class="loan-limit_el">
              Ei maksuhäiriömerkintöjä
            </div>
          </div>
        </div>
      </section>
      <section class="section loan-steps">
        <div class="container">
          <h2 class="title is-size-3 has-text-centered">Näin Lainakone toimii</h2>
          <div class="columns">
            <div class="column is-4 loan-steps_step">
              <div class="loan-steps_step_title">
                01
              </div>
              <div class="loan-steps_step_subtitle">
                Täytä hakemus
              </div>
              <div class="loan-steps_step_content">
                Aloita täyttämällä pyydetyt tiedot.
                Nopeimmillaan tämä vie vain pari minuuttia.
              </div>
            </div>
            <div class="column is-4 loan-steps_step">
              <div class="loan-steps_step_title">
                02
              </div>
              <div class="loan-steps_step_subtitle">
                Kilpailutamme pankit
              </div>
              <div class="loan-steps_step_content">
                Kilpailutamme puolestasi jopa 25 eri lainantarjoajaa. Nopeasti ja ilmaiseksi. Hakemus ei sido sinua mihinkään.
              </div>
            </div>
            <div class="column is-4 loan-steps_step">
              <div class="loan-steps_step_title">
                03
              </div>
              <div class="loan-steps_step_subtitle">
                Valitse paras
              </div>
              <div class="loan-steps_step_content">
                Saat lainatarjouksia jo muutamassa minuutissa. Valitse sopivin ja laina voi olla tililläsi vielä tänään.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="container">
          <p class="title has-text-centered">Usein kysytyt kysymykset</p>
          <accordion :items="questions" :columns="2" />
        </div>
      </section>
    </template>
    <lazy-hydrate ssr-only>
      <footer-comp />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  layout: 'whitelabel',
  components: {
    accordion: () => import( '~/components/accordion' ),
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'Desc'
    const theme = '#1d559c'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/stara/favicon.png' ) }]
    }
  },
  data () {
    return {
      name: 'stara',
      domain: 'stara.fi',
      questions: [
        {
          title: 'Miksi kannatta hakea lainaa Lainakoneen kautta?',
          content: 'Lainakone toimii lainankilpailuttajana, jolloin hakemuksesi tavoittaa useat eri pankit ja luotonmyöntäjät. Kun hakemuksesi tulee Lainakoneen kautta, lainamyöntäjät tietävät kilpailevansa keskenään. Tästä johtuen saat usein paremman tarjouksen Lainakoneen kautta, kuin hakemalla suoraan rahoitusyhtiöltä. Tämä on myös nopeampi tapa, vain yksi hakemus ja saat kerralla useamman tarjouksen vertailtavaksi.'
        },
        {
          title: 'Voinko yhdistää vanhoja lainoja palvelun avulla?',
          content: 'Lainakone auttaa myös nykyisten lainojen yhdistämisessä. Edullisemman koron lisäksi voit säästää huomattavan summan rahaa jo pelkästään maksamalla vain yhden lainan korot ja kulut useiden sijaan.'
        },
        {
          title: 'Mikä on lainan korko?',
          content: 'Rahoitusyhtiöt tekevät itse lainapäätökset lainasummien sekä korkojen suhteen. He käyttävät apunaan mm. tulo- ja menoarviota, jolloin tarjoukset ovat luotettavia ja ottavat huomioon asiakkaan maksukyvyn. <a href="#interestRate">Esimerkkilaskurin</a> avulla pystyy selvittämään suuntaa antavan koron ja kk-erän.'
        },
        {
          title: 'Voiko lainan maksaa aiottua nopeammin pois?',
          content: 'Ylimääräisiä suorituksia voi tehdä milloin vain, jolloin takaisinmaksuaika lyhenee. Voit myös maksaa koko lainan yhdessä erässä pois. Korkoa maksetaan ainoastaan siltä ajalta, kun laina on ollut käytössä. Ennenaikaisesta takaisinmaksu järjestelystä kannattaa sopia aina rahoitusyhtiön kanssa, josta lainan on ottanut.'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #00456e;
$wl-body-background: #fff;

$wl-navbar-background: #182432;
$wl-navbar-height: 8rem;
$wl-navbar-logo-height: 5rem;

$wl-footer-background: #00456e;
$wl-footer-color: white;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.stara {
  @include whitelabel;

  .navbar-item {
    color: white;
    text-transform: uppercase;
  }

  .loan-limit {

    @include touch {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &_container {
      display: flex;
      justify-content: space-around;

      @include touch {
        display: block;
      }
    }

    &_el {
      position: relative;
      margin-left: 65px;

      @include touch {
        margin: 2em 0 2em 65px;
      }

      &:before,
      &:after {
        content: " ";
        display: block;
        position: absolute;
      }

      &:before {
        border: 20px solid $wl-primary;
        border-radius: 50%;
        height: 0;
        width: 0;
        left: -50px;
        top: 0;
        margin-top: -.5em;
      }

      &:after {
        width: 10px;
        height: 20px;
        border: solid #fff;
        border-width: 0 4px 4px 0;
        left: -34px;
        top: 5px;
        margin-top: -5px;
        transform: rotate(45deg);
      }
    }
  }

  .loan-steps {
    background: $wl-primary;
    color: white;

    .title {
      color: white;
    }

    &_step {
      border-radius: 5px;
      text-align: center;
      padding-left: 2.5em;
      padding-right: 2.5em;
      box-sizing: border-box;

      @include touch {
        margin: 0 auto;
        margin-bottom: 2rem;
      }

      &_title {
        font-weight: 700;
        font-size: 2rem;
      }

      &_subtitle {
        margin-bottom: .25rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  .accordion {
    &__title {
      background: $wl-primary;
      color: white;
      padding: 1rem 0.5rem;
      border-radius: 5px;
      margin-bottom: 1rem;

      svg {
        color: white;
      }
    }

    &__content {
      padding: 0 1rem 1rem;
    }
  }
}
</style>
