import { loadDynamicScript } from '../loader'

export function load () {
  loadDynamicScript( '//www.googletagmanager.com/gtag/js?id=AW-957363591' )
  window.dataLayer = window.dataLayer || []
  window.gtag = function () {
    window.dataLayer.push( arguments )
  }
  window.gtag( 'js', new Date() )
  window.gtag( 'config', 'AW-957363591' )
}

export function onApplicationSent ( form ) {
  let result = false
  if ( typeof window.gtag === 'function' ) {
    try {
      window.gtag( 'event', 'conversion', { send_to: 'AW-957363591/Pvh8CIOzzPgBEIfrwMgD' } )
      result = true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
  }

  return result
}
