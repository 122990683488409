export function load () {
  if ( this.app.$config.RELEASE_STAGE !== 'production' ) {
    const clone = ( ...args ) => console.log( 'Facebook:', ...args )
    window.fbq = clone
    return
  }
  /* eslint-disable */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  /* eslint-enable */
  // Init facebook pixel
  if ( typeof window.fbq === 'function' ) {
    try {
      window.fbq( 'init', '174690356477729' )
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
  }
}

export function onPageView () {
  if ( typeof window.fbq === 'function' ) {
    try {
      window.fbq( 'track', 'PageView' )
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
  }
  return false
}

export function onIncompleteSent ( form ) {
  if ( Array.isArray( window.__rpta ) ) {
    try {
      window.fbq( 'trackCustom', ' incomplete_sent', {
        lainasumma: form.luottoraja,
        lainaaika: form.maksuaika
      } )
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
    return false
  }
}

export function onApplicationSent ( form ) {
  if ( typeof window.fbq === 'function' ) {
    try {
      window.fbq( 'trackCustom', 'application_sent', {
        lainasumma: form.luottoraja,
        lainaaika: form.maksuaika
      } )
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
  }
  return false
}
