import { loadDynamicScript } from '../loader'

export function load () {
  /**
   * Load the script when conditions match:
   *   - is production.
   *   - is in specified page.
   *   - has marketing consent.
   */
  if (
    this.app.$config.RELEASE_STAGE === 'production' &&
    Object.values( this.trackTypes ).includes( 3 )
  ) {
    window._nQc = '89249675'
    loadDynamicScript( '//serve.albacross.com/track.js', 'albacross' )
  }
}
