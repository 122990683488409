import { loadDynamicScript } from '../loader'

export function load () {
  /**
   * Load the script when conditions match
   *   - is production
   *   - does have arrive
   *   - arrive starts with RL_rp
   */
  if (
    this.app.$config.RELEASE_STAGE === 'production' &&
    Object.prototype.hasOwnProperty.call( this.route.query, 'arrive' ) &&
    ( this.route.query.arrive.substring( 0, 5 ) === 'RL_rp' || this.route.query.arrive.substring( 0, 5 ) === 'RL_RP' )
  ) {
    loadDynamicScript( '//app.readpeak.com/js/rp-instant.js' )
  }
}
