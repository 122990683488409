<template>
  <div class="container">
    <section class="section">
      <div class="terms content">
        <p>
          Lainahakemusprosessi siirtyy hakemuksen täyttämisen jälkeen
          kokonaisuudessaan Rahalaitokselle, joka on sinuun yhteydessä heti
          hakemuksen lähetettyäsi.
        </p>
        <button class="button is-primary" @click="closeWindow">
          Sulje ehdot
        </button>
        <h1 class="title">Palvelusopimus</h1>
        <p>
          Rahalaitos.fi (Sambla Group Oy, 2521965-8) tarjoaa lainavertailua sekä alla
          yksilöityjä vakuutus- ja luottokorttituotteisiin liittyviä palveluja
          kuluttajille ja yrityksille.
        </p>
        <h3 id="soveltamisala">1. Soveltamisala</h3>
        <p>
          Näitä yleisiä sopimusehtoja sovelletaan Rahalaitos.fi
          (Sambla Group Oy, 2521965-8) (”Rahalaitos”) ja laina/luotto-,
          vakuutus- tai muuhun rahoitusalaan liittyvän tuotteen -hakemuksen
          tekevän asiakkaan (”Asiakas”) välisessä palvelusopimus-suhteessa.
        </p>
        <h3 id="palvelun-kuvaus">2. Palvelun kuvaus</h3>
        <p>
          Palvelulla (”Palvelu”) tarkoitetaan Rahalaitoksen tarjoamien
          erilaisten rahoitus- tai muiden alojen kilpailutus- ja
          vertailupalvelua. Tällä hetkellä palvelua tarjotaan lainojen, luotto-
          ja maksuaikakorttien sekä vakuutusten vertailuun, välitykseen ja/tai
          kilpailutukseen.
        </p>
        <p>
          Tämä palvelusopimus astuu voimaan, kun Asiakas tekee Palvelua koskevan
          hakemuksen ja samalla asiakas rekisteröityy Rahalaitoksen asiakkaaksi.
        </p>
        <ol type="i">
          <li>
            Asiakkaan tehdessä Palvelua koskevan hakemuksen Rahalaitos välittää
            hakemuksen yksilöityjen tietojen perusteella (esim. haettu
            lainamäärä ja laina-aika) Rahalaitoksen arvion mukaan soveltuville
            Rahalaitoksen yhteistyökumppaneille (esim. pankki, muu
            rahoituslaitos ja/tai vakuutusyhtiö). Yhteistyökumppaneita voi olla
            yksi tai useampi. Haettu lainan määrä ja laina-aika voi poiketa
            hakemuksen halutusta lainan määrästä tai laina-ajasta. Rahalaitos
            pyrkii hakemaan hakijalle lainan, joka on lähellä hakijan haluamaa
            lainaa kuitenkin niin, että hakija saisi lainatarjouksia.
            <p>
              Rahalaitoksen kumppaneina toimivat yritykset käyvät ilmi
              verkkosivuiltamme
              <nuxt-link
                to="/"
              >https://{{ $domain }}</nuxt-link>
            </p>
          </li>
        </ol>
        <p>
          Rahalaitos pyrkii toimittamaan Palvelun viipymättä. Asiakas kuitenkin
          hyväksyy sen, että Palvelun perusteena olevat luotto- yms. päätökset
          eivät ole Rahalaitoksen tekemiä eikä Rahalaitos siksi voi sitoutua
          Palvelun käsittely- ja toimitusaikoihin. Tavanomaisesti Palvelua
          koskevat tarjoukset välitetään Asiakkaalle saman tai seuraavan
          pankkipäivän aikana.
        </p>
        <p>
          Asiakkaalle luodaan rekisteröitymisen yhteydessä oma asiakastili
          (Oma Rahalaitos), johon kirjautumalla Asiakas saa mahdolliset
          tarjoukset vertailtavakseen. Mikäli Palvelun toimittaminen sitä
          edellyttää, yhteydenpidossa voidaan käyttää myös Asiakkaan ilmoittamia
          yhteydenpitokanavia (puhelin, sähköposti).
        </p>
        <p>
          Tämä sopimus ei velvoita asiakasta hyväksymään Rahalaitoksen kumppanin
          tarjoamaa rahoitusta tai muuta tuotetta. Palvelun tarkoituksena on
          tarjota Asiakkaalle useita vaihtoehtoja ja siten antaa Asiakkaalle
          Rahalaitoksen välittämistä vaihtoehdoista mahdollisuuden valita tai
          olla valitsematta rahoitustarjous tai muu Asiakkaan tarpeisiin sopiva
          tuote.
        </p>
        <h3 id="tietojen-keraaminen-kasittely-sailyttaminen-ja-markkinointi">
          3. Tietojen kerääminen, käsittely, säilyttäminen ja markkinointi
        </h3>
        <p>
          Palvelun tuottaminen ja asiakkuuden ylläpitäminen edellyttää Asiakasta
          koskevien tietojen keräämistä, käsittelemistä ja säilyttämistä.
          Asiakas hyväksyy, että Asiakkaan tietoja analysoidaan myös Palvelun
          kehittämiseksi, markkinointiin ja väärinkäytösten estämiseksi.
        </p>
        <p>
          Rahalaitos saa asiakasta koskevia tietoja Asiakkaan hakemuksesta
          (asiakkaan antamat tiedot) sekä ulkopuolisista rekistereistä
          (esimerkiksi luottotiedot). Asiakas vastaa antamiensa tietojen
          oikeellisuudesta. Asiakas hyväksyy sen, että hakemuksessa yksilöidyt
          henkilötiedot, Asiakkaan IP-osoite sekä Palvelun toteuttamisen
          kannalta olennaiset luottotiedot selvitetään Rahalaitoksen ja/tai sen
          yhteistyökumppaneiden toimesta. Asiakastiedot rekisteröidään ja
          välitetään Palvelun edellyttämällä tavalla eteenpäin kyseistä Palvelua
          tarjoavalle Rahalaitoksen yhteistyökumppanille.
        </p>
        <p>
          Rahalaitos ja sen yhteistyökumppanit noudattavat tietojen käsittelyssä
          ja tietoturvassa kulloisenkin lainsäädännön edellyttämiä vaatimuksia
          ja standardeja. Tarkempia tietoja Rahalaitoksen tietosuojakäytännöistä
          <nuxt-link
            to="/tietosuojaseloste"
          >https://{{ $domain }}/tietosuojaseloste</nuxt-link>
        </p>
        <p>
          Asiakkaalla on oikeus saada tietää, mitä Asiakkaan henkilötietoja
          Rahalaitos käsittelee. Asiakkaalla on oikeus saada (i) tietojaan
          koskeva rekisteriote, (ii) pyytää virheellisten tai puutteellisten
          tietojen korjausta, (iii) pyytää henkilötietojen poistamista tai
          niiden käsittelyn rajoittamista. Rahalaitos käsittelee selvitys- tai
          poistopyynnön, jonka jälkeen selvitys annetaan, tiedot poistetaan tai
          Rahalaitos ilmoittaa perustellun syyn, miksi tietoja ei voida poistaa.
          Asiakas hyväksyy, että Rahalaitoksella on aina oikeus ja velvollisuus
          säilyttää sellaiset Asiakasta koskevat tiedot, jotka
        </p>
        <ul>
          <li>perustuvat Asiakkaan asiakkuuteen; ja</li>
          <li>
            Rahalaitoksen tulee säilyttää omien velvoitteidensa ja kulloinkin
            voimassaolevan lainsäädännön (esimerkiksi viranomaisten
            tietopyynnöt; kirjanpitolainsäädäntö) edellyttämällä tavalla.
          </li>
        </ul>
        <p>
          Rahalaitoksella on oikeus markkinoida palveluitaan Asiakkaalle, mikäli
          Asiakas ei tätä erikseen kiellä tai pakottavasta lainsäädännöstä ei
          muuta johdu. Asiakas voi koska tahansa ottaa yhteyttä Rahalaitoksen
          asiakaspalveluun
          <a href="mailto:info@rahalaitos.fi">info@rahalaitos.fi</a>
          ja pyytää suoramarkkinoinnin estämistä.
        </p>
        <h3 id="valtuus">4. Valtuutus</h3>
        <p>
          Palvelun toteuttaminen edellyttää asiakasta koskevien tietojen
          vaihtoa Rahalaitoksen ja sen yhteistyökumppaneiden välillä. Tällä
          sopimuksella Asiakas valtuuttaa Rahalaitoksen pyytämään ja
          vastaanottamaan Asiakkaan hakemukseen tai hakemuksen johdosta
          hyväksyttyyn lopputuotteeseen liittyviä tietoja
          yhteistyökumppaneiltaan. Tämä on tarpeen Rahalaitoksen ja sen
          yhteistyökumppaneiden sopimussuhteissa sekä mahdollisissa hakemuksiin
          liittyvissä selvitys- ja täydennystarpeissa. Selvyyden vuoksi
          todetaan, että Rahalaitoksella on oikeus välittää asiakkaan hakemus
          yhdelle tai useammalle Rahalaitoksen yhteistyökumppanille.
          Rahalaitoksella ei ole velvollisuutta välittää asiakkaan tietoja
          eteenpäin Rahalaitos.fi –palvelun yhteistyöpankeille,
          luotonmyöntäjille, vakuutusyhtiöille ja/tai muille Rahalaitoksen
          yhteistyökumppaneille, eikä Rahalaitoksella ole velvollisuutta
          selittää päätöstään tai yhteistyöpankin ja/tai muun tahon
          luottopäätöksen tai muun päätöksen perusteita.
        </p>
        <h3 id="hinnat">5. Hinnat</h3>
        <p>Rahalaitoksen tuottama Palvelu on Asiakkaalle maksutonta.</p>
        <h3 id="palvelun-keskeyttaminen-ja-vastuunrajoitus">
          6. Palvelun keskeyttäminen ja vastuunrajoitus
        </h3>
        <p>Rahalaitoksella on oikeus keskeyttää Palvelu, jos</p>
        <ul>
          <li>
            keskeyttäminen on tarpeen Palvelun käytettävyyden tai toimivuuden
            varmistamiseksi tai muun syyn johdosta. Rahalaitos pyrkii
            ilmoittamaan Asiakkaalle keskeytyksestä etukäteen;
          </li>
          <li>
            Rahalaitoksella on perusteltu syy epäillä Asiakkaan antamia
            tietoja virheellisiksi;
          </li>
          <li>
            Palvelua on käytetty lainvastaiseen tai hyvän tavan vastaiseen
            toimintaan.
          </li>
        </ul>
        <p>
          Rahalaitos ei vastaa välillisistä vahingoista tai vahingoista, joka
          johtuu Asiakkaan antamista virheellisistä tiedoista tai Asiakkaan Oma
          Rahalaitos -palvelun väärinkäytöstä. Asiakas vastaa Oma Rahalaitos
          -palvelun käyttäjätunnuksen ja salasanan turvallisesta
          säilyttämisestä.
        </p>
        <p>
          Asiakas hyväksyy, että Palveluun liittyviä tietoja voidaan käsitellä
          Asiakkaan ilmoittamien yhteydenpitokanavien (puhelin, sähköposti)
          kautta. Asiakas vastaa omien laitteidensa tietoturvasta ja
          asianmukaisesta käsittelystä itse.
        </p>
        <p>
          Selvyyden vuoksi todetaan, että Rahalaitos ei ole asiakkaan ja
          luoton-/vakuutuksenmyöntäjän (pankki, muu rahoituslaitos tai
          vakuutusyhtiö) välisen sopimuksen osapuoli eikä tämä sopimus velvoita
          asiakasta luotto- tai vakuutussopimuksen hyväksymiseen. Rahalaitos ei
          osallistu Asiakkaalle välitettävien luotto- tai
          vakuutussopimustarjousten laadintaan eikä vastaa niiden sisällöstä.
          Mikäli asiakas hyväksyy luotto- tai vakuutustarjouksen,
          luotto-/vakuutussopimuksen ehdot määräytyvät asiakkaan ja ko.
          palveluntarjoajan välillä erikseen. Rahalaitos ei vastaa mistään
          Asiakkaan ja luoton-/vakuutuksenmyöntäjän välisen sopimuksen
          velvoitteista tai vahingosta.
        </p>
        <h3 id="evasteet-ja-verkkoanalytiikka">
          7. Evästeet ja verkkoanalytiikka
        </h3>
        <p>
          Rahalaitos käyttää verkkosivustollaan erilaisia analytiikkaratkaisuja,
          joiden avulla seurataan sivuston ja palveluiden käyttöä. Analysoinnin
          ja evästeiden tarkoituksena on mahdollistaa henkilökohtaisempi
          palveluiden tarjoaminen sekä sivuston ylläpitäminen ja kehittäminen.
          Lisätietoa Rahalaitoksen evästekäytännöstä
          <nuxt-link
            to="/tietoja-evasteista"
          >https://{{ $domain }}/tietoja-evasteista</nuxt-link>
        </p>
        <h3 id="evasteet-ja-verkkoanalytiikka">8. Sopimuksen irtisanominen</h3>
        <p>
          Asiakas voi irtisanoa tämän sopimuksen päättymään ilmoittamalla siitä
          sähköpostitse Rahalaitokselle (yhteystiedot alla). Selvyyden vuoksi
          Rahalaitos korostaa, että tämän sopimuksen päättyminen ei vaikuta
          Asiakkaan ja luoton-/vakuutuksenmyöntäjän välisen sopimuksen
          voimassaoloon eikä sellaisten edellä käsiteltyjen tietojen
          poistamiseen, joiden säilyttämiseen Rahalaitoksella on jo
          toteutettujentoimien johdosta oikeus tai velvollisuus.
        </p>
        <h3 id="sopimusehtojen-paivittaminen">
          9. Sopimusehtojen päivittäminen
        </h3>
        <p>
          Rahalaitos varaa oikeuden Palvelusopimuksen muuttamiseen milloin
          tahansa tätä sivua päivittämällä. Ilmoitamme olennaisista muutoksista
          verkkosivuillamme tai sähköpostiviestillä.
        </p>
        <p>
          Asiakas voi irtisanoa tämän sopimuksen päättymään ilmoittamalla siitä
          sähköpostitse Rahalaitokselle (yhteystiedot alla). Selvyyden vuoksi
          Rahalaitos korostaa, että tämän sopimuksen päättyminen ei vaikuta
          Asiakkaan ja luoton-/vakuutuksenmyöntäjän välisen sopimuksen
          voimassaoloon eikä sellaisten edellä käsiteltyjen tietojen
          poistamiseen, joiden säilyttämiseen Rahalaitoksella on jo
          toteutettujen toimien johdosta oikeus tai velvollisuus.
        </p>
        <h3 id="yhteystiedot">10. Yhteystiedot</h3>
        <p>
          Rahalaitos.fi<br>
          <a href="mailto:info@rahalaitos.fi">info@rahalaitos.fi</a><br>
          puhelin 09 2311 3670<br>
          (ma-pe 9.00-16.00)
        </p>
        <h3 class="title">Rahalaitos.fi asiakasrekisterin tietosuojaseloste</h3>
        <p>
          Tässä Tietosuojaselosteessa kerrotaan muun muassa, minkälaisia
          henkilötietoja me käsittelemme, miten me käsittelemme henkilötietoja
          ja miten voit käyttää rekisteröidyn oikeuksiasi (esimerkiksi oikeus
          vastustaa, oikeus päästä tietoihisi).
        </p>
        <h3 id="rekisterinpitaja">Rekisterinpitäjä</h3>
        <p>
          Sambla Group Oy<br>
          Yliopistonkatu 58 B, 33100 Tampere<br>
          <a href="mailto:info@rahalaitos.fi">info@rahalautos.fi</a><br>
          09 2311 3670
        </p>
        <h3 id="tietosuojavastaava">Tietosuojavastaava</h3>
        <p>
          Sambla Group Oy:llä on tietosuojavastaava.
          Tietosuojavastaavan tehtävänä on olla muun muassa asiakkaiden ja
          työntekijöiden tukena sekä varmistaa, että noudatamme lakeja ja muita
          sääntöjä, jotka koskevat henkilötietojen käsittelyä ja tietosuojaa
          yleisesti. Voit ottaa yhteyttä tietosuojavastaavaan sähköpostitse:
          info@rahalaitos.fi tai postitse osoitteeseen: Tietosuojavastaava,
          Sambla Group Oy, Yliopistonkatu 58 B, 33100 Tampere
        </p>
        <h3 id="minka-takia-kasittelemme-henkilotietojasi">Minkä takia käsittelemme henkilötietojasi</h3>
        <h4>Hakemuksesi välittäminen ja kilpailuttaminen</h4>
        <p>
          Meidän tehtävänämme on hakemuksesi perusteella kilpailuttaa haluamasi
          laina tai vakuutus meidän yhteistyökumppaneillamme, tai toimittaa
          luottokorttihakemuksesi yhteistyökumppaneillemme. Tätä tarkoitusta
          varten meidän on luovutettava henkilötietojasi
          yhteistyökumppaneillemme, jotta he voivat tehdä sinulle tarjouksen
          edellä mainituista palveluista.
        </p>
        <h4>Asiakassuhteen hoitaminen</h4>
        <p>
          Jotta voimme kilpailuttaa lainasi tai toimittaa
          luottokorttihakemuksesi puolestasi, meillä on oltava sinun
          paikkansapitävät henkilötiedot asiakasviestintää varten. Kun lähetät
          meille kilpailutus- tai luottokorttihakemuksen, sinusta tulee
          asiakasohjelmamme jäsen.
        </p>
        <h4>Markkinointi</h4>
        <p>
          Haluamme antaa sinulle ajankohtaista tietoa tarjoamistamme palveluista
          ja sen vuoksi haluamme lähettää sinulle markkinointia esimerkiksi
          tekstiviestein sekä sähköpostitse tai puhelimitse soittamalla.
          Automatisoitujen soittojärjestelmien, sähköpostiviestien,
          tekstiviestien, puheviestien, ääniviestien, kuvaviestien tai muun
          markkinointivälineen avulla toteutettua suoramarkkinointia saamme
          kohdistaa sinulle vain, jos olet antanut siihen ennalta suostumuksesi.
        </p>
        <h4>Yhteishakija</h4>
        <p>
          Asiakkaanamme voit ilmoittaa lainan kilpailutustilanteessa myös
          yhteishakijan henkilötiedot. Muistutamme, että sinulla on ehdottomasti
          oltava yhteishakijan lupa hänen henkilötietojen antamiseen. Meillä on
          oikeus lähettää ilmoittamallesi yhteishakijalle sinun nimesi, eli
          taho, jolta olemme yhteishakijan henkilötiedot saaneet.
        </p>
        <h3 id="minka-takia-meilla-on-oikeus-kasitella-henkilotietojasi">
          Minkä takia meillä on oikeus käsitellä henkilötietojasi
        </h3>
        <p>
          EU:n yleinen tietosuoja-asetus edellyttää, että meillä
          rekisterinpitäjänä on laillinen peruste henkilötietojesi käsittelyyn.
          Meidän perusteemme henkilötietojesi käsittelyyn ovat sopimussuhteen
          hoitaminen ja sopimuksen tekemistä edeltävien toimenpiteiden
          toteuttaminen (esimerkiksi lainahakemuksen välittäminen ja lainan
          kilpailuttaminen sekä asiakassuhteen hoitaminen) sekä oikeutettu
          etumme (esimerkiksi asiakassuhteen hoitaminen ja markkinointi).  Lain
          mukaan meidän tulee tarkastaa, onko asiakas poliittisesti
          vaikutusvaltainen henkilö.
        </p>
        <p>
          Joissain tapauksissa Rahalaitoksella on lakisääteinen velvollisuus
          luovuttaa tietoja poliisille tai Finanssivalvonnalle, kun kyseessä on
          rikosepäily, esimerkiksi petos tai toisen henkilöllisyyden luvaton
          käyttö.
        </p>
        <h3 id="mita-tietoja-keraamme">Mitä tietoja keräämme</h3>
        <p>
          Voimme kerätä sinusta asiakasrekisteriimme hakemuksen yhteydessä
          antamasi tiedot. Keräämme tietoja myös yhteydenpidosta asiakkaan
          kanssa sähköpostitse, tekstiviestitse, chat-kanavan kautta ja
          tapauskohtaisesti puhelinkeskustelujen tallenteina.
        </p>
        <p>
          Henkilötietojen antaminen on palveluidemme käyttämisen edellytys.
        </p>
        <h3 id="mista-saamme-henkilotietojasi">Mistä saamme henkilötietojasi</h3>
        <p>
          Suurimman osan tiedoistasi saamme sinulta, kun täytät lainahakemuksen.
          Tämän lisäksi saamme henkilötietojasi myös luottotietorekisteriä
          ylläpitäviltä tahoilta (esimerkiksi Bisnode Finland Oy) sekä
          yhteistyökumppaneinamme toimivilta rahoituslaitoksilta, joille
          lähetämme hakemuksesi.
        </p>
        <h3 id="kenelle-luovutamme-henkilotietojasi">
          Kenelle luovutamme henkilötietojasi
        </h3>
        <p>
          Jotta voimme kilpailuttaa laina- tai vakuutushakemuksesi, meidän on
          lähetettävä se yhteistyö-kumppaneillemme eli rahoituslaitoksille ja
          mahdollisten vakuutustuotteiden osalta vakuutusyhtiöille.
          Henkilötietojasi voivat käsitellä myös myynti -ja
          markkinointikumppanimme, jotka ovat sitoutuneet noudattamaan
          tietosuojalainsäädäntöä.
        </p>
        <p>
          Luovutamme henkilötietojasi myös viranomaisille, mikäli lainsäädäntö
          meidät siihen velvoittaa. Tietoja luovuttaessamme annamme ainoastaan
          ne tiedot, joita kyseinen yhteistyökumppanimme tai viranomainen
          sopimuksen taikka lainsäädännön vuoksi tarvitsee. Lisäksi edellytämme,
          että tietojen vastaanottajalla on laillinen peruste tietojesi
          käsittelyyn.
        </p>
        <h3 id="miten-kasittelemme-henkilotietojasi">
          Miten käsittelemme henkilötietojasi
        </h3>
        <p>
          Jotta tarjoamamme palvelu olisi nopeaa ja tasapuolista,
          lainahakemuksesi käsitellään pääsääntöisesti ensin automaattisesti.
          Ohjelmistomme muun muassa saattaa tarkastaa automaattisesti
          luottokelpoisuutesi antamiesi tietojen perusteella. EU:n yleisen
          tietosuoja-asetuksen mukaan sinulla on oikeus vastustaa automaattista
          päätöksentekoa. Koska päätöksentekomme perustuu tapauksesta riippuen
          joko kokonaan tai osittain automaatioon, emme valitettavasti voi
          käsitellä hakemustasi, jos vastustat meidän käyttävän automaattista
          päätöksentekoa.
        </p>
        <h3 id="missa-kasittelemme-henkilotietojasi">
          Missä käsittelemme henkilötietojasi
        </h3>
        <p>
          Lähtökohtaisesti käsittelemme henkilötietojasi Euroopan unionin (EU)
          ja Euroopan talousalueen (ETA) sisällä. Jos tietojasi siirretään EU:n
          ja ETA:n ulkopuolelle, huolehdimme henkilötietojen suojan riittävästä
          tasosta muun muassa sopimalla henkilötietojen luottamuksellisuuteen ja
          käsittelyyn liittyvistä asioista lainsäädännön edellyttämällä tavalla.
        </p>
        <h3 id="kuinka-pitkaan-sailytamme-henkilotietojasi">
          Kuinka pitkään säilytämme henkilötietojasi
        </h3>
        <p>
          Säilytämme tietojasi niin kauan, kun tietojesi käsittelylle on edellä
          selostettu lain mukainen peruste, ellei meitä velvoittava lainsäädäntö
          edellytä pidempää säilytysaikaa. Heti, kun tiedot eivät ole enää
          tarpeen, poistamme ne niin pian kuin mahdollista. Huomioithan, että
          saatamme olla velvollisia säilyttämään henkilötietojasi sinun ja
          meidän välisen sopimuksen päättymisen jälkeen muun muassa
          kirjanpitolain ja terrorismin rahoittamisen ja rahanpesun estämistä
          koskevan lainsäädännön perusteella.
        </p>
        <p>
          Meillä on myös oikeutettu peruste säilyttää joitain henkilötietojasi
          kymmenen (10) vuotta mahdollisen petoksen ja muiden rikosten
          ehkäisemiseen liittyvistä syistä. Kyseisessä tarkoituksessa käsitellyt
          henkilötiedot kattavat ainoastaan rikoksen selvittämistä varten
          tarvittavat tiedot.
        </p>
        <h3 id="sinun-oikeutesi">Sinun oikeutesi</h3>
        <p>
          Sinulla on oikeus nähdä, oikaista ja poistaa henkilötietojasi.
          Sinulla on myös oikeus rajoittaa ja vastustaa henkilötietojesi
          käsittelyä sekä oikeus siirtää tiedot järjestelmästä toiseen eli
          oikeus saada omat Rahalaitokselle antamasi henkilötiedot, joita
          käsitellään sähköisesti. Voit saada tiedot suoraan itsellesi tai
          pyytää, että ne toimitetaan toiselle
          toimittajalle/rekisterinpitäjälle. Ellei toisin pyydetä, tiedot
          toimitetaan yleisesti käytetyssä sähköisessä muodossa rekisteröimääsi
          sähköpostiosoitteeseen. Jotta voit käyttää oikeuksiasi, sinun on
          vahvistettava henkilöllisyytesi. Yhteystiedot löytyvät tämän
          tietosuojalausunnon alusta.
        </p>
        <p>
          Jos koet, että Rahalaitoksen henkilötietojen käsittely loukkaa
          yksityisyyttä koskevaa lainsäädäntöä, sinulla on oikeus tehdä valitus
          tietosuojavaltuutetulle.
        </p>
        <h3 id="tietosuojaselosteen-paivittyminen">
          Tietosuojaselosteen päivittyminen
        </h3>
        <p>
          Nämä tiedot saattavat muuttua, jos palvelumme tai yksityisyyttä
          koskeva lainsäädäntö muuttuu. Ajantasaiset tiedot löytyvät aina
          verkkosivuiltamme osoitteesta
          <nuxt-link to="/">{{ $domain }}</nuxt-link>
        </p>
        <p>
          Verkossa ja sovelluksessa käytettävien evästeiden osalta tarkemmat
          tiedot evästekäytännostämme löytyvät verkkosivuiltamme osoittesta
          <nuxt-link to="/tietoja-evasteista">
            {{ $domain }}/tietoja-evasteista
          </nuxt-link>.
        </p>
        <p>
          Tämä tietosuojaseloste on päivitetty tammikuussa 2019.
          Pidätämme oikeuden muuttaa tätä selostetta aika ajoin.
        </p>
        <button class="button is-primary" @click="closeWindow">
          Sulje ehdot
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  layout: 'whitelabel',
  props: {
    siteName: {
      type: String,
      required: true
    }
  },
  head () {
    return {
      title: `${this.siteName} | Yleiset ehdot`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `${this.siteName} yleiset ehdot.`
        }
      ]
    }
  },
  methods: {
    closeWindow () {
      window.close()
    }
  }
}
</script>
