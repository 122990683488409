type LoanType = 'consumer' | 'business' | 'mortgage'

interface SliderDefaults {
  min: number,
  max: number,
  step: number,
  default: number
}

interface ApplicationSliders {
  amount: SliderDefaults,
  period: SliderDefaults
}

type TypeSliderDefaults = {
  [K in LoanType]: ApplicationSliders
}
/**
 * Holds default values for sliders
 */
export const defaults: TypeSliderDefaults = {
  consumer: {
    amount: {
      max: 70000,
      min: 500,
      step: 100,
      default: 15000
    },
    period: {
      max: 1,
      min: 15,
      step: 1,
      default: 8
    }
  },
  business: {
    amount: {
      min: 1000,
      max: 250000,
      step: 1000,
      default: 20000
    },
    period: {
      min: 1,
      max: 36,
      step: 1,
      default: 6
    }
  },
  mortgage: {
    amount: {
      min: 50000,
      max: 500000,
      step: 5000,
      default: 200000
    },
    period: {
      min: 1,
      max: 30,
      step: 1,
      default: 15
    }
  }
}
/**
 * Returns default amount for slider.
 * @param slider   Which slider to return.
 * @param loanType Loan type to use.
 * @returns number
 */
export function getDefaults ( slider: 'amount' | 'period', loanType: LoanType = 'consumer' ): number {
  return defaults[loanType][slider].default
}
/**
 * Calculates loan details.
 *
 * @export
 * @param {number}  loanAmount Loan amount.
 * @param {number}  loanPeriod Loan period.
 * @param {string}  loanType   Loan type.
 * @returns
 */
export function calculate ( loanAmount: number, loanPeriod: number, loanType: LoanType = 'consumer' ) {
  interface CalculatorRange {
    min: number,
    max: number,
    interest: {
      nominal: number,
      effective: number
    }
  }

  // If number is invalid use default
  if ( isNaN( loanAmount ) ) {
    loanAmount = defaults[loanType].amount.default
  }
  if ( isNaN( loanPeriod ) ) {
    loanPeriod = defaults[loanType].period.default
  }

  const loanCalculatorArrays:{[key in LoanType]: CalculatorRange[]} = {
    consumer: [
      { min: Number.MIN_SAFE_INTEGER, max: 4900, interest: { nominal: 10.0, effective: 10.71 } },
      { min: 5000, max: 9900, interest: { nominal: 9.5, effective: 9.92 } },
      { min: 10000, max: 14900, interest: { nominal: 8.75, effective: 9.11 } },
      { min: 15000, max: 19900, interest: { nominal: 7.75, effective: 8.03 } },
      { min: 20000, max: 24900, interest: { nominal: 6.85, effective: 7.07 } },
      { min: 25000, max: 29900, interest: { nominal: 6.45, effective: 6.64 } },
      { min: 30000, max: 34900, interest: { nominal: 6, effective: 6.17 } },
      { min: 35000, max: 39900, interest: { nominal: 5.45, effective: 5.59 } },
      { min: 40000, max: 50000, interest: { nominal: 5, effective: 5.12 } },
      { min: 50100, max: Number.MAX_SAFE_INTEGER, interest: { nominal: 4.5, effective: 4.59 } }
    ],
    business: [
      { min: Number.MIN_SAFE_INTEGER, max: 9000, interest: { nominal: 18.0, effective: 0 } },
      { min: 10000, max: 19000, interest: { nominal: 17.5, effective: 0 } },
      { min: 20000, max: 29000, interest: { nominal: 17.0, effective: 0 } },
      { min: 30000, max: 49000, interest: { nominal: 16.5, effective: 0 } },
      { min: 50000, max: 74000, interest: { nominal: 16.5, effective: 0 } },
      { min: 75000, max: 100000, interest: { nominal: 16.0, effective: 0 } },
      { min: 101000, max: 150000, interest: { nominal: 15.5, effective: 0 } },
      { min: 151000, max: 200000, interest: { nominal: 14.0, effective: 0 } },
      { min: 201000, max: 250000, interest: { nominal: 12.0, effective: 0 } },
      { min: 251000, max: 500000, interest: { nominal: 9.0, effective: 0 } },
      { min: 501000, max: Number.MAX_SAFE_INTEGER, interest: { nominal: 7.8, effective: 0 } }
    ],
    mortgage: []
  }

  /**
   * Get's correct nominal and effective from array.
   *
   * @param {number} loanAmount Loan amount.
   * @returns
   */
  const get = ( loanAmount: number ) => {
    const amount = Math.round( loanAmount / 100 ) * 100
    return loanCalculatorArrays[loanType]
      .filter( interest =>
        amount >= interest.min && amount <= interest.max
      )[0].interest
  }

  let period = loanPeriod
  if ( loanType !== 'business' ) {
    period = loanPeriod * 12
  }

  // Calculates loan
  const interest = get( loanAmount )
  const nominal = interest.nominal / 100
  const subvalue = Math.pow( 1 + ( nominal / 12 ), -period )
  const result = loanAmount * ( nominal / 12 / ( 1 - subvalue ) )
  const total = result * period
  const expenses = total - loanAmount

  return {
    monthly: String( isNaN( result )
      ? 0
      : Math.round( result ) ).replace( /(\d*)(\d{3})$/, '$1 $2' ),
    expenses: String( isNaN( expenses )
      ? 0
      : Math.round( expenses ) ).replace( /(\d*)(\d{3})$/, '$1 $2' ),
    total: String( isNaN( total )
      ? 0
      : Math.round( total ) ).replace( /(\d*)(\d{3})$/, '$1 $2' ),
    nominal: interest.nominal.toFixed( 2 ).toString().replace( '.', ',' ),
    effective: interest.effective.toFixed( 2 ).toString().replace( '.', ',' )
  }
}
