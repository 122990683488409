export const expName = 'NewRahalaitos2'

export const expID = 'uZAkyOtBRNmNRFCpzlCvuQ'

export const campaigns = {
  google: [
    'RL_google_b'
    // 'RL_google_2'
  ],
  bing: [
    'RL_bing',
    'RL_bing_co',
    'RL_bing_b',
    'RL_bing_yhdistalaina',
    'RL_bing_ulk'
  ],
  sms: [
    'RL_sms1',
    'RL_sms2',
    'RL_sms3',
    'RL_sms4'
  ],
  mail: [
    'RL_mail1',
    'RL_mail2',
    'RL_mail3',
    'RL_mail4'
  ]
}

export const campaignsArray = Object.values( campaigns ).flat()

export function findCampaignCategory ( target ) {
  for ( const [category, items] of Object.entries( campaigns ) ) {
    if ( items.includes( target ) ) {
      return category
    }
  }
  return null
}

export function isBlacklistedCheck ( { req, store, $cookies } ) {
  let ip = null
  let removeCookie = false
  if ( process.server ) {
    ip = req.headers['x-forwarded-for'] || req.socket.remoteAddress
    store.commit( 'SET_IP', ip )
  }
  else if ( process.client ) {
    ip = store.state.ip
  }
  if ( ip ) {
    const blacklist = [
      '213.141.104.204',
      '213.141.104.205',
      '213.141.104.206',
      '212.50.154.247',
      '62.165.169.51',
      '52.31.182.22'
    ]

    if ( blacklist.includes( ip ) ) {
      removeCookie = true
    }
  }
  // Blocked referers
  const referer = process.server ? req.headers.referer : document.referrer
  const refererBlocklist = [
    'hallinta.rahalaitos.fi',
    'crm.rahalaitos.fi'
  ]
  if ( typeof referer === 'string' ) {
    refererBlocklist.forEach( ( url ) => {
      if ( referer.includes( url ) ) {
        removeCookie = true
      }
    } )
  }
  // Remove cookie when from internal sources
  if ( removeCookie ) {
    const host = process.server ? req.headers.host : window.location.host.split( ':' )[0]
    const rootDomain = host.replace( /^www\./, '' )
    if ( $cookies ) {
      $cookies.remove( 'exp', {
        domain: `.${rootDomain}`,
        path: '/'
      } )
      // Remove both cookies just to be sure.
      $cookies.remove( 'exp' )
    }
  }
  return removeCookie
}

export function isAllowedCampaign ( arrive ) {
  const arriveIsWhitelisted = campaignsArray.includes( arrive )
  return arriveIsWhitelisted
}

export function checkEligble ( { route, req, store, $cookies }, checkCampaign = true ) {
  // Do not enable on WL and cc extranet
  const host = req ? req.headers.host : window.location.host.split( ':' )[0]
  const isWL = ['hakemus', 'app', 'lainakone', 'kulutusluotot', 'hae'].includes(
    host.split( '.' )[0]
  )
  // Dissallow blacklist
  const isBlacklisted = isBlacklistedCheck( { req, store, $cookies } )
  if ( isBlacklisted ) {
    return false
  }
  // Disallow PWA
  if ( route.query.standalone ) {
    return false
  }
  // Do not enable if whitelabel
  else if ( isWL ) {
    return false
  }
  // Do not enable on cc extranet
  else if ( route.path.startsWith( '/cc-new' ) ) {
    return false
  }
  // Do not enable on iframe's
  else if ( route.path.includes( 'iframe' ) ) {
    return false
  }
  // Only allow index page
  else if ( route.path !== '/' ) {
    return false
  }
  //
  // Enable on specific campaigns only
  //
  if ( checkCampaign ) {
    if ( isAllowedCampaign( route.query.arrive ) ) {
      return true
    }
    else {
      return false
    }
  }
  // Bypass campaign check on redirect except if another campaign
  else {
    // Do not redirect if another campaign
    if ( route.query.arrive && !isAllowedCampaign( route.query.arrive ) ) {
      return false
    }
    return true
  }
}
