import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f68af73 = () => interopDefault(import('../pages/application-stored.vue' /* webpackChunkName: "pages/application-stored" */))
const _25a62015 = () => interopDefault(import('../pages/autolaina.vue' /* webpackChunkName: "pages/autolaina" */))
const _7d2cb3be = () => interopDefault(import('../pages/autorahoitus.vue' /* webpackChunkName: "pages/autorahoitus" */))
const _707b872e = () => interopDefault(import('../pages/cc-new/index.vue' /* webpackChunkName: "pages/cc-new/index" */))
const _eccdf1c2 = () => interopDefault(import('../pages/cc-new/index/kuluttaja.vue' /* webpackChunkName: "pages/cc-new/index/kuluttaja" */))
const _44e2d398 = () => interopDefault(import('../pages/cc-new/index/yritys.vue' /* webpackChunkName: "pages/cc-new/index/yritys" */))
const _0e22670b = () => interopDefault(import('../pages/etutuoterahoitus.vue' /* webpackChunkName: "pages/etutuoterahoitus" */))
const _49fa7b53 = () => interopDefault(import('../pages/identiteettivakuutus.vue' /* webpackChunkName: "pages/identiteettivakuutus" */))
const _4784c1d7 = () => interopDefault(import('../pages/iframe.vue' /* webpackChunkName: "pages/iframe" */))
const _00b2eb2a = () => interopDefault(import('../pages/iframe/leadgroup.vue' /* webpackChunkName: "pages/iframe/leadgroup" */))
const _703fe3f3 = () => interopDefault(import('../pages/iframe-yrityslaina.vue' /* webpackChunkName: "pages/iframe-yrityslaina" */))
const _4d5a3c7f = () => interopDefault(import('../pages/iframe-yrityslaina/yrityslainaheti.vue' /* webpackChunkName: "pages/iframe-yrityslaina/yrityslainaheti" */))
const _5eb3f8ae = () => interopDefault(import('../pages/jarjestelylaina.vue' /* webpackChunkName: "pages/jarjestelylaina" */))
const _1c4f1f7a = () => interopDefault(import('../pages/joustoluotto.vue' /* webpackChunkName: "pages/joustoluotto" */))
const _fb9a6c52 = () => interopDefault(import('../pages/kilpailuta-lainat.vue' /* webpackChunkName: "pages/kilpailuta-lainat" */))
const _26c02e19 = () => interopDefault(import('../pages/kokemuksia.vue' /* webpackChunkName: "pages/kokemuksia" */))
const _331ed32d = () => interopDefault(import('../pages/lainaa.vue' /* webpackChunkName: "pages/lainaa" */))
const _1e003d76 = () => interopDefault(import('../pages/lainaa-1000-euroa.vue' /* webpackChunkName: "pages/lainaa-1000-euroa" */))
const _c5743450 = () => interopDefault(import('../pages/lainaa-10000-euroa.vue' /* webpackChunkName: "pages/lainaa-10000-euroa" */))
const _7f23af77 = () => interopDefault(import('../pages/lainaa-11000-euroa.vue' /* webpackChunkName: "pages/lainaa-11000-euroa" */))
const _069ad5f3 = () => interopDefault(import('../pages/lainaa-15000-euroa.vue' /* webpackChunkName: "pages/lainaa-15000-euroa" */))
const _11bd5fce = () => interopDefault(import('../pages/lainaa-20000-euroa.vue' /* webpackChunkName: "pages/lainaa-20000-euroa" */))
const _60764034 = () => interopDefault(import('../pages/lainaa-25000-euroa.vue' /* webpackChunkName: "pages/lainaa-25000-euroa" */))
const _50fcba5a = () => interopDefault(import('../pages/lainaa-30000-euroa.vue' /* webpackChunkName: "pages/lainaa-30000-euroa" */))
const _aa4fb6ca = () => interopDefault(import('../pages/lainaa-40000-euroa.vue' /* webpackChunkName: "pages/lainaa-40000-euroa" */))
const _b511381c = () => interopDefault(import('../pages/lainaa-5000-euroa.vue' /* webpackChunkName: "pages/lainaa-5000-euroa" */))
const _04b38edc = () => interopDefault(import('../pages/lainaa-50000-euroa.vue' /* webpackChunkName: "pages/lainaa-50000-euroa" */))
const _5e8ef91d = () => interopDefault(import('../pages/lainaa-60000-euroa.vue' /* webpackChunkName: "pages/lainaa-60000-euroa" */))
const _8f2b3944 = () => interopDefault(import('../pages/lainaa-70000-euroa.vue' /* webpackChunkName: "pages/lainaa-70000-euroa" */))
const _490ff324 = () => interopDefault(import('../pages/lainahakemus.vue' /* webpackChunkName: "pages/lainahakemus" */))
const _4e6363e7 = () => interopDefault(import('../pages/lainakumppanit.vue' /* webpackChunkName: "pages/lainakumppanit" */))
const _d68c3a74 = () => interopDefault(import('../pages/lainavertailu.vue' /* webpackChunkName: "pages/lainavertailu" */))
const _d3556648 = () => interopDefault(import('../pages/luotto.vue' /* webpackChunkName: "pages/luotto" */))
const _24958139 = () => interopDefault(import('../pages/markkinointikielto.vue' /* webpackChunkName: "pages/markkinointikielto" */))
const _161e7385 = () => interopDefault(import('../pages/mmkisat.vue' /* webpackChunkName: "pages/mmkisat" */))
const _077a16c4 = () => interopDefault(import('../pages/moottoripyoralaina.vue' /* webpackChunkName: "pages/moottoripyoralaina" */))
const _46f91523 = () => interopDefault(import('../pages/oma/index.vue' /* webpackChunkName: "pages/oma/index" */))
const _4eec25d8 = () => interopDefault(import('../pages/remonttilaina.vue' /* webpackChunkName: "pages/remonttilaina" */))
const _191d0140 = () => interopDefault(import('../pages/sijoituslaina.vue' /* webpackChunkName: "pages/sijoituslaina" */))
const _589d3014 = () => interopDefault(import('../pages/smskielto.vue' /* webpackChunkName: "pages/smskielto" */))
const _8b4b5978 = () => interopDefault(import('../pages/talous/index.vue' /* webpackChunkName: "pages/talous/index" */))
const _169cc76e = () => interopDefault(import('../pages/tietoja-evasteista.vue' /* webpackChunkName: "pages/tietoja-evasteista" */))
const _3310b6fe = () => interopDefault(import('../pages/venelaina.vue' /* webpackChunkName: "pages/venelaina" */))
const _76aeb161 = () => interopDefault(import('../pages/yhdista-lainat.vue' /* webpackChunkName: "pages/yhdista-lainat" */))
const _63f90b86 = () => interopDefault(import('../pages/yhdistelylaina.vue' /* webpackChunkName: "pages/yhdistelylaina" */))
const _aabe941e = () => interopDefault(import('../pages/yhteistyokumppanit/index.vue' /* webpackChunkName: "pages/yhteistyokumppanit/index" */))
const _54ceddbe = () => interopDefault(import('../pages/yleiset-ehdot.vue' /* webpackChunkName: "pages/yleiset-ehdot" */))
const _a2639ece = () => interopDefault(import('../pages/yrityksen-rahoitus.vue' /* webpackChunkName: "pages/yrityksen-rahoitus" */))
const _4a876b5e = () => interopDefault(import('../pages/yritys.vue' /* webpackChunkName: "pages/yritys" */))
const _0b4332d4 = () => interopDefault(import('../pages/yrityslainaa-5000-euroa.vue' /* webpackChunkName: "pages/yrityslainaa-5000-euroa" */))
const _e30a5f76 = () => interopDefault(import('../pages/yrityslainakumppanit.vue' /* webpackChunkName: "pages/yrityslainakumppanit" */))
const _5d2987c4 = () => interopDefault(import('../pages/yrityslainat.vue' /* webpackChunkName: "pages/yrityslainat" */))
const _acce7160 = () => interopDefault(import('../pages/yritysrahoitus.vue' /* webpackChunkName: "pages/yritysrahoitus" */))
const _dd2c4100 = () => interopDefault(import('../pages/cc-new/login.vue' /* webpackChunkName: "pages/cc-new/login" */))
const _d6da3320 = () => interopDefault(import('../pages/talous/ala-hae-lainaa-mista-tahansa.vue' /* webpackChunkName: "pages/talous/ala-hae-lainaa-mista-tahansa" */))
const _2ad9e092 = () => interopDefault(import('../pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla.vue' /* webpackChunkName: "pages/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla" */))
const _076f6ef1 = () => interopDefault(import('../pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton.vue' /* webpackChunkName: "pages/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton" */))
const _b95c9872 = () => interopDefault(import('../pages/talous/haaveiletko-urheilureissusta.vue' /* webpackChunkName: "pages/talous/haaveiletko-urheilureissusta" */))
const _54a131f7 = () => interopDefault(import('../pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia.vue' /* webpackChunkName: "pages/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia" */))
const _10a81296 = () => interopDefault(import('../pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat.vue' /* webpackChunkName: "pages/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat" */))
const _1a2bc4b6 = () => interopDefault(import('../pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa.vue' /* webpackChunkName: "pages/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa" */))
const _e764bdac = () => interopDefault(import('../pages/talous/korkokatto-paattyy-joulukuussa.vue' /* webpackChunkName: "pages/talous/korkokatto-paattyy-joulukuussa" */))
const _8018902c = () => interopDefault(import('../pages/talous/korkokatto-putosi-kymmeneen-prosenttiin.vue' /* webpackChunkName: "pages/talous/korkokatto-putosi-kymmeneen-prosenttiin" */))
const _42e1f1fb = () => interopDefault(import('../pages/talous/korkokattoa-jatkettiin-syksyyn.vue' /* webpackChunkName: "pages/talous/korkokattoa-jatkettiin-syksyyn" */))
const _2eda138d = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena" */))
const _d7cf042a = () => interopDefault(import('../pages/talous/kulutusluottojen-korot-putosivat-selvasti.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-korot-putosivat-selvasti" */))
const _a6dc9f18 = () => interopDefault(import('../pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle" */))
const _32ff345f = () => interopDefault(import('../pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta.vue' /* webpackChunkName: "pages/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta" */))
const _43b640f4 = () => interopDefault(import('../pages/talous/lainat-kannattaa-yhdistaa-heti.vue' /* webpackChunkName: "pages/talous/lainat-kannattaa-yhdistaa-heti" */))
const _3e1cbc6b = () => interopDefault(import('../pages/talous/lainojen-yhdistaminen-kannatti.vue' /* webpackChunkName: "pages/talous/lainojen-yhdistaminen-kannatti" */))
const _6f272315 = () => interopDefault(import('../pages/talous/mita-tekisit-200-eurolla.vue' /* webpackChunkName: "pages/talous/mita-tekisit-200-eurolla" */))
const _3bf866ba = () => interopDefault(import('../pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla.vue' /* webpackChunkName: "pages/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla" */))
const _4618b692 = () => interopDefault(import('../pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla.vue' /* webpackChunkName: "pages/talous/nain-loydat-luotettavan-lainan-edullisella-korolla" */))
const _518938a6 = () => interopDefault(import('../pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista.vue' /* webpackChunkName: "pages/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista" */))
const _cc7606e4 = () => interopDefault(import('../pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa.vue' /* webpackChunkName: "pages/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa" */))
const _e9fcac26 = () => interopDefault(import('../pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi.vue' /* webpackChunkName: "pages/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi" */))
const _26de9e5f = () => interopDefault(import('../pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta.vue' /* webpackChunkName: "pages/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta" */))
const _00da27b0 = () => interopDefault(import('../pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa.vue' /* webpackChunkName: "pages/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa" */))
const _f6df7f0a = () => interopDefault(import('../pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa.vue' /* webpackChunkName: "pages/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa" */))
const _af99d6e2 = () => interopDefault(import('../pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan.vue' /* webpackChunkName: "pages/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan" */))
const _602a9c22 = () => interopDefault(import('../pages/yhteistyokumppanit/_company.vue' /* webpackChunkName: "pages/yhteistyokumppanit/_company" */))
const _3acce46f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/application-stored",
    component: _7f68af73,
    name: "application-stored"
  }, {
    path: "/autolaina",
    component: _25a62015,
    name: "autolaina"
  }, {
    path: "/autorahoitus",
    component: _7d2cb3be,
    name: "autorahoitus"
  }, {
    path: "/cc-new",
    component: _707b872e,
    name: "cc-new",
    children: [{
      path: "kuluttaja",
      component: _eccdf1c2,
      name: "cc-new-index-kuluttaja"
    }, {
      path: "yritys",
      component: _44e2d398,
      name: "cc-new-index-yritys"
    }]
  }, {
    path: "/etutuoterahoitus",
    component: _0e22670b,
    name: "etutuoterahoitus"
  }, {
    path: "/identiteettivakuutus",
    component: _49fa7b53,
    name: "identiteettivakuutus"
  }, {
    path: "/iframe",
    component: _4784c1d7,
    name: "iframe",
    children: [{
      path: "leadgroup",
      component: _00b2eb2a,
      name: "iframe-leadgroup"
    }]
  }, {
    path: "/iframe-yrityslaina",
    component: _703fe3f3,
    name: "iframe-yrityslaina",
    children: [{
      path: "yrityslainaheti",
      component: _4d5a3c7f,
      name: "iframe-yrityslaina-yrityslainaheti"
    }]
  }, {
    path: "/jarjestelylaina",
    component: _5eb3f8ae,
    name: "jarjestelylaina"
  }, {
    path: "/joustoluotto",
    component: _1c4f1f7a,
    name: "joustoluotto"
  }, {
    path: "/kilpailuta-lainat",
    component: _fb9a6c52,
    name: "kilpailuta-lainat"
  }, {
    path: "/kokemuksia",
    component: _26c02e19,
    name: "kokemuksia"
  }, {
    path: "/lainaa",
    component: _331ed32d,
    name: "lainaa"
  }, {
    path: "/lainaa-1000-euroa",
    component: _1e003d76,
    name: "lainaa-1000-euroa"
  }, {
    path: "/lainaa-10000-euroa",
    component: _c5743450,
    name: "lainaa-10000-euroa"
  }, {
    path: "/lainaa-11000-euroa",
    component: _7f23af77,
    name: "lainaa-11000-euroa"
  }, {
    path: "/lainaa-15000-euroa",
    component: _069ad5f3,
    name: "lainaa-15000-euroa"
  }, {
    path: "/lainaa-20000-euroa",
    component: _11bd5fce,
    name: "lainaa-20000-euroa"
  }, {
    path: "/lainaa-25000-euroa",
    component: _60764034,
    name: "lainaa-25000-euroa"
  }, {
    path: "/lainaa-30000-euroa",
    component: _50fcba5a,
    name: "lainaa-30000-euroa"
  }, {
    path: "/lainaa-40000-euroa",
    component: _aa4fb6ca,
    name: "lainaa-40000-euroa"
  }, {
    path: "/lainaa-5000-euroa",
    component: _b511381c,
    name: "lainaa-5000-euroa"
  }, {
    path: "/lainaa-50000-euroa",
    component: _04b38edc,
    name: "lainaa-50000-euroa"
  }, {
    path: "/lainaa-60000-euroa",
    component: _5e8ef91d,
    name: "lainaa-60000-euroa"
  }, {
    path: "/lainaa-70000-euroa",
    component: _8f2b3944,
    name: "lainaa-70000-euroa"
  }, {
    path: "/lainahakemus",
    component: _490ff324,
    name: "lainahakemus"
  }, {
    path: "/lainakumppanit",
    component: _4e6363e7,
    name: "lainakumppanit"
  }, {
    path: "/lainavertailu",
    component: _d68c3a74,
    name: "lainavertailu"
  }, {
    path: "/luotto",
    component: _d3556648,
    name: "luotto"
  }, {
    path: "/markkinointikielto",
    component: _24958139,
    name: "markkinointikielto"
  }, {
    path: "/mmkisat",
    component: _161e7385,
    name: "mmkisat"
  }, {
    path: "/moottoripyoralaina",
    component: _077a16c4,
    name: "moottoripyoralaina"
  }, {
    path: "/oma",
    component: _46f91523,
    name: "oma"
  }, {
    path: "/remonttilaina",
    component: _4eec25d8,
    name: "remonttilaina"
  }, {
    path: "/sijoituslaina",
    component: _191d0140,
    name: "sijoituslaina"
  }, {
    path: "/smskielto",
    component: _589d3014,
    name: "smskielto"
  }, {
    path: "/talous",
    component: _8b4b5978,
    name: "talous"
  }, {
    path: "/tietoja-evasteista",
    component: _169cc76e,
    name: "tietoja-evasteista"
  }, {
    path: "/venelaina",
    component: _3310b6fe,
    name: "venelaina"
  }, {
    path: "/yhdista-lainat",
    component: _76aeb161,
    name: "yhdista-lainat"
  }, {
    path: "/yhdistelylaina",
    component: _63f90b86,
    name: "yhdistelylaina"
  }, {
    path: "/yhteistyokumppanit",
    component: _aabe941e,
    name: "yhteistyokumppanit"
  }, {
    path: "/yleiset-ehdot",
    component: _54ceddbe,
    name: "yleiset-ehdot"
  }, {
    path: "/yrityksen-rahoitus",
    component: _a2639ece,
    name: "yrityksen-rahoitus"
  }, {
    path: "/yritys",
    component: _4a876b5e,
    name: "yritys"
  }, {
    path: "/yrityslainaa-5000-euroa",
    component: _0b4332d4,
    name: "yrityslainaa-5000-euroa"
  }, {
    path: "/yrityslainakumppanit",
    component: _e30a5f76,
    name: "yrityslainakumppanit"
  }, {
    path: "/yrityslainat",
    component: _5d2987c4,
    name: "yrityslainat"
  }, {
    path: "/yritysrahoitus",
    component: _acce7160,
    name: "yritysrahoitus"
  }, {
    path: "/cc-new/login",
    component: _dd2c4100,
    name: "cc-new-login"
  }, {
    path: "/talous/ala-hae-lainaa-mista-tahansa",
    component: _d6da3320,
    name: "talous-ala-hae-lainaa-mista-tahansa"
  }, {
    path: "/talous/auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla",
    component: _2ad9e092,
    name: "talous-auton-hankinnassa-voi-saastaa-reilusti-kilpailuttamalla"
  }, {
    path: "/talous/essi-ja-jere-hyodynsivat-lainojen-korkokaton",
    component: _076f6ef1,
    name: "talous-essi-ja-jere-hyodynsivat-lainojen-korkokaton"
  }, {
    path: "/talous/haaveiletko-urheilureissusta",
    component: _b95c9872,
    name: "talous-haaveiletko-urheilureissusta"
  }, {
    path: "/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia",
    component: _54a131f7,
    name: "talous-hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia"
  }, {
    path: "/talous/kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat",
    component: _10a81296,
    name: "talous-kansainvaliset-digipankit-ovat-myllyttaneet-suomen-lainamarkkinat"
  }, {
    path: "/talous/katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa",
    component: _1a2bc4b6,
    name: "talous-katso-5-kohdan-listaus-jolla-helpotat-taloutesi-hoitoa"
  }, {
    path: "/talous/korkokatto-paattyy-joulukuussa",
    component: _e764bdac,
    name: "talous-korkokatto-paattyy-joulukuussa"
  }, {
    path: "/talous/korkokatto-putosi-kymmeneen-prosenttiin",
    component: _8018902c,
    name: "talous-korkokatto-putosi-kymmeneen-prosenttiin"
  }, {
    path: "/talous/korkokattoa-jatkettiin-syksyyn",
    component: _42e1f1fb,
    name: "talous-korkokattoa-jatkettiin-syksyyn"
  }, {
    path: "/talous/kulutusluottojen-korot-laskivat-korkokaton-seurauksena",
    component: _2eda138d,
    name: "talous-kulutusluottojen-korot-laskivat-korkokaton-seurauksena"
  }, {
    path: "/talous/kulutusluottojen-korot-putosivat-selvasti",
    component: _d7cf042a,
    name: "talous-kulutusluottojen-korot-putosivat-selvasti"
  }, {
    path: "/talous/kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle",
    component: _a6dc9f18,
    name: "talous-kulutusluottojen-lakimuutos-sai-suomalaiset-liikkeelle"
  }, {
    path: "/talous/kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta",
    component: _32ff345f,
    name: "talous-kulutusluottojen-saannot-uusiksi-nain-hyodyt-korkokatosta"
  }, {
    path: "/talous/lainat-kannattaa-yhdistaa-heti",
    component: _43b640f4,
    name: "talous-lainat-kannattaa-yhdistaa-heti"
  }, {
    path: "/talous/lainojen-yhdistaminen-kannatti",
    component: _3e1cbc6b,
    name: "talous-lainojen-yhdistaminen-kannatti"
  }, {
    path: "/talous/mita-tekisit-200-eurolla",
    component: _6f272315,
    name: "talous-mita-tekisit-200-eurolla"
  }, {
    path: "/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla",
    component: _3bf866ba,
    name: "talous-miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla"
  }, {
    path: "/talous/nain-loydat-luotettavan-lainan-edullisella-korolla",
    component: _4618b692,
    name: "talous-nain-loydat-luotettavan-lainan-edullisella-korolla"
  }, {
    path: "/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista",
    component: _518938a6,
    name: "talous-nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista"
  }, {
    path: "/talous/oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa",
    component: _cc7606e4,
    name: "talous-oletko-jo-kokeillut-lainojen-kilpailuttamista-verkossa"
  }, {
    path: "/talous/petri-yhdisti-pienet-lainansa-ja-hammastyi",
    component: _e9fcac26,
    name: "talous-petri-yhdisti-pienet-lainansa-ja-hammastyi"
  }, {
    path: "/talous/piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta",
    component: _26de9e5f,
    name: "talous-piia-saastaa-yli-300-euroa-kuukaudessa-laskeneen-korkokaton-ansiosta"
  }, {
    path: "/talous/pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa",
    component: _00da27b0,
    name: "talous-pitkaan-odotettu-reissu-edessa-ja-rahat-vahissa"
  }, {
    path: "/talous/teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa",
    component: _f6df7f0a,
    name: "talous-teija-yhdisti-lainansa-ja-saastaa-nyt-kuluissa"
  }, {
    path: "/talous/vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan",
    component: _af99d6e2,
    name: "talous-vakuudettomien-lainojen-korkoja-rajoittava-laki-astui-voimaan"
  }, {
    path: "/yhteistyokumppanit/:company",
    component: _602a9c22,
    name: "yhteistyokumppanit-company"
  }, {
    path: "/",
    component: _3acce46f,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
