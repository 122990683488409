import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import { isBlacklistedCheck } from '~/utils/samblaRedirect'

export default ( { $config, app, req, route, store, $cookies }, inject ) => {
  // init settings
  const options = {
    id: $config.RELEASE_STAGE === 'production'
      ? ['UA-2455771-8']
      : 'UA-54222947-4',
    router: app.router,
    ecommerce: {
      enabled: true
    },
    disabled: false
  }

  // Should we disable google analytics, even if consent is given?
  const host = req ? req.headers.host : window.location.host.split( ':' )[0]
  const isWL = ['hakemus', 'app', 'lainakone', 'kulutusluotot', 'hae', 'lainahakemus'].includes(
    host.split( '.' )[0]
  )

  // disable if statistics consent not given.
  if ( isWL || route.path.startsWith( '/cc-new' ) ) {
    options.disabled = true
  }

  // Holds options set array
  const optionsSet = []

  // Internal cookie logic
  if ( route.query.internal === 'true' || app.$cookies.get( 'internalTraffic' ) ) {
    // Set the cookie
    $cookies.set( 'internalTraffic', 'true', {
      path: '/',
      maxAge: 60 * 60 * 24 * 365 * 3
    } )
    // Set GA dimension
    optionsSet.push( {
      field: 'cd1',
      value: 'Level 1'
    } )
  }
  // Set options
  options.set = optionsSet

  // Inject analytics
  Vue.use( VueAnalytics, options )
  inject( 'ga', Vue.$ga )
}
