<!--
  This page is used dynamically for all the other thousand sub-pages.
  You can find the logic from nuxt.config.js router.extendRoutes().
-->
<template>
  <div class="content">
    <breadcrumb
      :path="{'Lainaa': '/lainaa', [`Lainaa ${amount} euroa`]: ''}"
    />
    <div class="subpage_floating">
      <sliders ref="sliders" :luottoraja="amount" class="is-hidden-touch" />
    </div>
    <h1>Lainaa {{ amount }} € alkaen {{ monthly }} €/kk</h1>
    <p>
      Rahalaitokselta voit hakea lainaa {{ amount }} euroa sinulle sopivalla
      maksuajalla. Hae {{ amount }} euron lainaa sivun reunassa olevalla
      hakemuslomakkeella. Alla olevassa lainataulukossa näet edullisimman
      luotonantajan hintatiedot {{ amount }} € luotoista. Kilpailutamme lainan jopa 25
      pankilla puolestasi, jolloin saat aina edullisen lainan.
    </p>
    <template v-if="amount % 3 === 0">
      <h2>Rahalaitoksen kautta löydät edullisen lainan</h2>
      <p>
        Kilpailuttamalla lainan Rahalaitoksella löydät edulliset lainatarjoukset. Rahalaitos välittää
        hakemuksesi jopa 25 pankille ja muille rahoituslaitoksille. Täytettyäsi
        hakemuksen, vastaanotat heti useita lainatarjouksia, joista sinä voit
        valita parhaiten sopivan. Autamme sinua säästämään aikaa, vaivaa ja
        rahaa.
      </p>
    </template>
    <template v-else-if="amount % 3 === 1">
      <h2>{{ amount }} euron laina ilman vakuuksia tai takaajia</h2>
      <p>
        Kilpailuttamalla lainan Rahalaitoksella löydät edulliset lainatarjoukset. Rahalaitos välittää
        hakemuksesi jopa 25 pankille ja muille rahoituslaitoksille. Täytettyäsi
        hakemuksen, vastaanotat heti useita lainatarjouksia, joista sinä voit
        valita parhaiten sopivan. Autamme sinua säästämään aikaa, vaivaa ja
        rahaa.
      </p>
    </template>
    <template v-else>
      <h2>Voit lainata {{ amount }} euroa jopa 15 vuoden maksuajalla</h2>
      <p>
        Hakemusta tehdessä valitset sinulle sopivan laina-ajan. Kun kyseessä on
        {{ amount }} € laina, voit valita maksuajaksi 1 - 15 vuotta. Mitä pidempään
        sinä lainaat, sitä pienempi on lainan kuukausierä. Voit helposti
        kokeilla miten laina-aika vaikuttaa kuukausierään laskurilla jonka
        löydät tältä sivulta.
      </p>
    </template>
    <div class="table-container is-mobile" style="max-width: 45rem;">
      <table class="table is-bordered is-fullwidth">
        <thead>
          <tr>
            <th class="numeric">Lainan suuruus</th>
            <th class="numeric">Laina-aika</th>
            <th class="numeric">Kuukausierä</th>
            <th class="numeric">Linkki</th>
          </tr>
        </thead>
        <tbody v-for="(value, index) in calculations" :key="index">
          <tr>
            <td data-title="Lainan suuruus">{{ amount }} €</td>
            <td data-title="Laina-aika">{{ index + 1 }} vuotta</td>
            <td data-title="Kuukausierä">{{ calculations[index] }} € /kk</td>
            <td data-title="Linkki">
              <nuxt-link :to="`/?lainasumma=${amount}&lainaaika=${index + 1}`">Kilpailuta laina >></nuxt-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { calculate as loanCalculator } from '~/utils/loanDetail'

export default {
  name: 'LainaaXX000Euroa',
  components: {
    sliders: () => import( '~/components/subpage/sliders' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  layout: 'subpage',
  data () {
    return {
      monthly: null,
      calculations: []
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa 15 000 euroa',
        subtitle: 'Ilman vakuuksia ja takaajia',
        link: '/lainaa-15000-euroa'
      },
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  head () {
    return {
      title: `Lainaa ${this.amount} euroa ilman vakuuksia | Lainapäätös heti`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Etsitkö ${this.amount} lainaa? Kilpailuta eri lainapaikat ilmaiseksi meillä - voit saada jopa 25
            lainatarjousta! Kokeile heti!`
        }
      ]
    }
  },
  computed: {
    amount () {
      return parseInt( this.$route.name.split( '-' )[1] )
    }
  },
  mounted () {
    // 1 - 15 Loan period
    for ( let index = 0; index < 15; index++ ) {
      this.calculations.push(
        loanCalculator( this.amount, index + 1 ).monthly
      )
    }
    this.monthly = this.calculations[14]
  }
}
</script>
