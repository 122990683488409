import Vue from 'vue'
import {
  load as FacebookLoad,
  onPageView as FacebookOnPageView,
  onIncompleteSent as FacebookOnIncompleteSent,
  onApplicationSent as FacebookOnApplicationSent
} from './services/facebook'
import {
  load as AdFormLoad,
  onApplicationSent as AdFormOnApplicationSent
} from './services/adform'
import {
  load as BingLoad,
  onApplicationSent as BingOnApplicationSent
} from './services/bing'
import {
  load as GoogleAsyncLoad,
  onApplicationSent as GoogleAsyncOnApplicationSent
} from './services/googleAsync'
import {
  load as GiosgLoad,
  onIncompleteSent as GiosgOnIncompleteSent,
  onApplicationSent as GiosgOnApplicationSent
} from './services/giosg'
import {
  onApplicationSent as ALehtiOnApplicationSent
} from './services/alehti'
import {
  load as readPeakLoad,
  onIncompleteSent as readPeakOnIncompleteSent,
  onApplicationSent as readPeakOnApplicationSent
} from './services/readPeak'
import {
  load as readPeakInstantLoad
} from './services/readPeakInstant'
import {
  load as Albacross
} from './services/albacross'
import {
  load as GtmLoad,
  onApplicationSent as GtmApplicationSent
} from './services/googleTagManager'

class Tracking {
  static get NECESSARY () {
    return 0 // VÄLTTÄMÄTTÖMÄT
  }

  static get PREFERENCES () {
    return 1 // MIELTYMYKSET
  }

  static get STATISTICS () {
    return 2 // STATISTIIKKA
  }

  static get MARKETING () {
    return 3 // MARKKINOINTI
  }

  static get CONSENT () {
    return this.consent
  }

  static set CONSENT ( value ) {
    this.consent = Boolean( value )
  }

  constructor ( app, route ) {
    this.app = app
    this.route = route
    // Is consent accepted?
    this.consent = false
    this.queue = {
      onPageView: [],
      onIncompleteSent: [],
      onApplicationSent: []
    }
    // Disabled by default
    this.enabled = false
  }

  /**
   * Enable tracking
   */
  enable () {
    this.enabled = true
  }

  /**
   * Disable tracking
   */
  disable () {
    this.enabled = false
  }

  /**
   * Loads tracking pixels and enables tracking
   *
   * @param {array[int]} trackTypes - [Tracking.MARKETING] etc..
   * @memberof Tracking
   */
  load ( trackTypes ) {
    this.enable()
    this.trackTypes = trackTypes
    const functions = {
      [Tracking.NECESSARY]: {
        GoogleAsyncLoad,
        BingLoad,
        GiosgLoad,
        GtmLoad,
        readPeakInstantLoad
      },
      [Tracking.PREFERENCES]: {
      },
      [Tracking.STATISTICS]: {
        readPeakLoad
      },
      [Tracking.MARKETING]: {
        AdFormLoad,
        Albacross,
        FacebookLoad
      }
    }
    trackTypes.forEach( ( trackType ) => {
      Object.keys( functions ).forEach( ( currentType ) => {
        if ( trackType === parseInt( currentType ) ) {
          Object.keys( functions[currentType] ).forEach( ( fun ) => {
            try {
              functions[currentType][fun].call( this )
            }
            catch ( error ) {
              this.app.$logger.error( error )
            }
          } )
        }
      } )
    } )
  }

  onPageView ( _to, _from ) {
    if ( this.enabled ) {
      const functions = [
        FacebookOnPageView
      ]
      functions.forEach( ( fun ) => {
        try {
          const result = fun.call( this )
          // Consent is not accepted so lets add track to queue and try again.
          if ( result === false && this.consent === false ) {
            this.queue.onPageView.push( fun )
          }
        }
        catch ( error ) {
          this.app.$logger.error( error )
        }
      } )
    }
  }

  onIncompleteSent ( form ) {
    if ( this.enabled ) {
      const functions = [
        FacebookOnIncompleteSent,
        GiosgOnIncompleteSent,
        readPeakOnIncompleteSent
      ]
      functions.forEach( ( fun ) => {
        try {
          fun.call( this, form )
        }
        catch ( error ) {
          this.app.$logger.error( error )
        }
      } )
      Vue.$ga.event( 'perustiedot', 'lähetä' )
      const isBusiness = Object.keys( form ).some( key => key.startsWith( 'business_' ) )
      Vue.$gtag.event( 'incomplete', {
        event_category: isBusiness ? 'businessLoanApplication' : 'consumerloanApplication',
        value: form.luottoraja
      } )
    }
  }

  onApplicationSent ( form ) {
    if ( this.enabled ) {
      const functions = [
        FacebookOnApplicationSent,
        AdFormOnApplicationSent,
        BingOnApplicationSent,
        GoogleAsyncOnApplicationSent,
        GiosgOnApplicationSent,
        ALehtiOnApplicationSent,
        readPeakOnApplicationSent,
        GtmApplicationSent
      ]
      functions.forEach( ( fun ) => {
        try {
          const result = fun.call( this, form )
          // Consent is not accepted so lets add track to queue and try again.
          if ( result === false && this.consent === false ) {
            this.queue.onApplicationSent.push( [fun, form] )
          }
        }
        catch ( error ) {
          this.app.$logger.error( error )
        }
      } )
      Vue.$ga.event( {
        eventCategory: 'lainahakemus',
        eventAction: 'lähetä',
        eventValue: form.luottoraja
      } )
      // Send ecommerce info when app is sent.
      Vue.$ga.ecommerce.addTransaction({
        id: form.orderid,
        value: form.luottoraja
      })
      Vue.$ga.ecommerce.addItem({
        id: form.orderid,
        name: 'Rahalaitos FI',
        price: form.luottoraja,
        category: form.tarkoitus,
        quantity: 1
      })
      Vue.$ga.ecommerce.send()
      const isBusiness = Object.keys( form ).some( key => key.startsWith( 'business_' ) )
      Vue.$gtag.event( 'sent', {
        event_category: isBusiness ? 'businessLoanApplication' : 'consumerloanApplication',
        value: form.luottoraja
      } )

      // Remove campaign cookies but not when cc extranet
      if ( !this.route.path.startsWith( '/cc-new' ) ) {
        this.app.$cookies.remove( 'RL_arrive' )
        this.app.$cookies.remove( 'RL_keyword' )
        this.app.$cookies.remove( 'RL_arrive_fp' )
        this.app.$cookies.remove( 'RL_arrive_fp2' )
        this.app.$cookies.remove( 'RL_arrive_fp3' )
        this.app.$cookies.remove( 'RL_arrive_fp4' )
        this.app.$cookies.remove( 'RL_arrive_fp5' )
      }
    }
  }

  onApplicationFailed () {
  }
}

export default Tracking
