import _ from 'lodash'
import axios from 'axios'

/**
 * Check from API if applications is duplicate.
 *
 * NOTICE: This can be only called on Server Side. Because we will not pass
 * API2 variables to front end.
 *
 * @param {Object} values Object that has to contain email or ssn keys.
 * @param {Logger} logger Logger class
 */
export default async function isDuplicate ( values = {}, logger ) {
  // Return false when no values passed
  if ( Object.entries( values ).length === 0 ) {
    return false
  }
  // Parse values
  const parsedValues = {
    email: _.has( values, 'email' ) ? values.email : undefined,
    ssn: _.has( values, 'ssn' ) ? values.ssn : undefined,
    location: _.has( values, 'location' ) ? values.location : undefined
  }
  // When either of values does exists check duplicate
  if ( parsedValues.email || parsedValues.ssn ) {
    try {
      const response = await axios.post(
        `${process.env.API2_URL}/Customer/Status`,
        parsedValues,
        {
          headers: {
            Authorization: `Basic ${process.env.API2_TOKEN}`
          }
        }
      )
      const { data } = response
      return data
    }
    // Catch axios error
    catch ( error ) {
      // If logger is set catch it into logger
      if ( logger ) {
        logger.error( error )
      }
      // Throw error
      else {
        throw error
      }
    }
  }
  // Otherwise return false
  else {
    return false
  }
}
