import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'

export const state = () => ( {
  form: {},
  formVisible: false
} )

export type LoanApplicationState = ReturnType<typeof state>

export const mutations: MutationTree<LoanApplicationState> = {
  MERGE_TO_FORM ( state, data ) {
    state.form = Object.assign( state.form, data )
  },
  CLEAR_FORM ( state ) {
    state.form = {}
  },
  FORM_VISIBLE ( state, value: boolean ) {
    state.formVisible = value
  }
}

export const actions: ActionTree<LoanApplicationState, RootState> = {
  async merge ( { commit }, data ) {
    await commit( 'MERGE_TO_FORM', data )
  },
  async clear ( { commit } ) {
    await commit( 'CLEAR_FORM' )
  }
}
