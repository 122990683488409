<template>
  <application
    v-if="!sent"
    ref="application"
    class="loan-application application"
    @incompleteSent="onIncompleteSent"
    @sent="onSent"
  />
  <confirmation
    v-else-if="Object.keys(confirmation).length"
    :id="orderid"
    class="loan-application confirmation"
    :confirmation="confirmation"
    :redirect-url="redirectURL"
  />
  <thank-you
    v-else
    :id="orderid"
    class="loan-application thank-you"
    :redirect-url="redirectURL"
  />
</template>

<script>
import _ from 'lodash'

export default {
  components: {
    application: () => import( '~/components/loanApplication/application' ),
    confirmation: () => import( '~/components/loanApplication/confirmation' ),
    thankYou: () => import( '~/components/loanApplication/thankYou' )
  },
  data () {
    return {
      confirmation: {},
      orderid: '',
      redirectURL: null,
      sent: false
    }
  },
  fetch () {
    this.$store.commit( 'loanApplication/FORM_VISIBLE', true )
  },
  destroyed () {
    this.$store.commit( 'loanApplication/FORM_VISIBLE', false )
  },
  methods: {
    onSent ( result, formData ) {
      if ( Object.prototype.hasOwnProperty.call( result, 'confirmation' ) && result.confirmation === true ) {
        this.confirmation = {
          bruttotulot: this.$refs.application.form.bruttotulot,
          nettotulot: this.$refs.application.form.nettotulot,
          email: this.$refs.application.form.email,
          puhelin: this.$refs.application.form.puhelin
        }
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'id' ) ) {
        this.orderid = result.id
      }
      if ( _.has( result, 'url' ) ) {
        this.redirectURL = result.url
      }
      // Send pixels
      this.$tracking.onApplicationSent( {
        luottoraja: this.$refs.application.form.luottoraja,
        maksuaika: this.$refs.application.form.maksuaika,
        tarkoitus: this.$refs.application.form.tarkoitus,
        orderid: this.orderid
      } )
      this.sent = true
      this.$emit( 'sent', formData )
    },
    onIncompleteSent () {
      // Pass event forward
      this.$emit( 'incompleteSent' )
    }
  }
}
</script>
