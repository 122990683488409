import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { Plugin } from '@nuxt/types'
import Logger from '~/utils/Logger/index'

export type $logger = {
  error( ...args: any ): void
  info( ...args: any ): void
  notify( ...args: any ): void
  warning( ...args: any ): void
}

declare module 'vue/types/vue' {
  // this.$myInjectedFunction inside Vue components
  interface Vue {
    $logger: $logger
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$myInjectedFunction inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $logger: $logger
  }
  // nuxtContext.$myInjectedFunction
  interface Context {
    $logger: $logger
  }
}

declare module 'vuex/types/index' {
  // this.$myInjectedFunction inside Vuex stores
  interface Store<S> {
    $logger: $logger
  }
}

const loggerPlugin: Plugin = ( { $config }, inject ) => {
  // Install bugsnag when bugsnag API key is set and bugsnag is enabled
  let BugsnagClient: any
  if ( ( $config.BUGSNAG_ENABLED === 'true' || $config.BUGSNAG_ENABLED === true ) && $config.BUGSNAG_JS_API_KEY ) {
    BugsnagClient = Bugsnag.start( {
      apiKey: $config.BUGSNAG_JS_API_KEY,
      releaseStage: $config.RELEASE_STAGE || 'development',
      appVersion: $config.APP_VERSION,
      plugins: [new BugsnagPluginVue()]
    } )
    const vuePlugin = Bugsnag.getPlugin( 'vue' )
    if ( vuePlugin ) {
      vuePlugin.installVueErrorHandler( Vue )
    }
  }
  // Inject logger into vue app and context
  const logger = new Logger( BugsnagClient )
  inject( 'logger', logger )
}

export default loggerPlugin
