import _ from 'lodash'

export default function ( { $axios, $config } ) {
  if ( $config.RELEASE_STAGE === 'production' ) {
    $axios.defaults.timeout = 15000
    $axios.defaults.withCredentials = true
  }

  $axios.onRequest( ( config ) => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // Axios clears empty valuables before it comes into nuxt so we have to
    // manually create it again
    if ( !config.headers.post ) {
      config.headers.post = {}
    }
    config.headers.post['Content-Type'] = 'application/json'
    config.xsrfCookieName = 'CSRF-Token'
    config.xsrfHeaderName = 'X-CSRF-Token'
    config.withCredentials = true
  } )

  if ( $config.AXIOS_DEBUG === 'true' ) {
    $axios.onRequestError( ( error ) => {
      const req = error.request
      // eslint-disable-next-line no-console
      console.error(
        '[Axios]',
        'Request error:',
        `[${_.get( req, 'config.method', '' ).toUpperCase()}] ${_.get( req, 'config.url', '-' )}`,
        error
      )
    } )
    $axios.onResponseError( ( error ) => {
      const res = error.response
      // eslint-disable-next-line no-console
      console.error(
        '[Axios]',
        'Response error:',
        `[${_.get( res, 'config.method', '' ).toUpperCase()}] ${_.get( res, 'config.url', '-' )}`,
        error
      )
    } )
    $axios.onResponse( ( res ) => {
      // eslint-disable-next-line no-console
      console.info(
        '[Axios]',
        `[${res.status} ${res.statusText}]`,
        `[${_.get( res, 'config.method', '' ).toUpperCase()}]`,
        _.get( res, 'config.url', '-' )
      )

      if ( process.browser ) {
        // eslint-disable-next-line no-console
        console.log( res )
      }
      else {
        // eslint-disable-next-line no-console
        console.log( JSON.stringify( res.data, undefined, 2 ) )
      }

      return res
    } )
  }
}
