/**
 * Custom router for vue.js
 *
 * NOTICE: Remember to add custom routes to sitemap options in nuxt.config.js till the issue with sitemap module is
 *         fixed https://github.com/nuxt-community/sitemap-module/issues/98
 */

import Vue from 'vue'
import Router from 'vue-router'
import parseQuery from './utils/parseQuery.js'
import Lainaa11000Euroa from '~/pages/lainaa-11000-euroa'
import YrityslainaaXXEuroa from '~/pages/yrityslainaa-5000-euroa'
import YhdistaLainat from '~/pages/yhdista-lainat.vue'
import Strapi from '~/components/strapi/page.vue'

Vue.use( Router )

export function createRouter ( ssrContext, createDefaultRouter, routerOptions, config ) {
  const host = ssrContext ? ssrContext.req.headers.host : window.location.host.split( ':' )[0]
  const isWL = ['hakemus', 'app', 'lainakone', 'kulutusluotot', 'hae', 'lainahakemus'].includes( host.split( '.' )[0] )
  const options = routerOptions || createDefaultRouter( ssrContext, config ).options
  let router
  if ( isWL ) {
    const site = host.split( '.' )[1]
    const Whitelabel = require( `~/whitelabels/${site}` ).default
    const Component = Vue.extend( Whitelabel )
    const data = Whitelabel.data()
    // Add custom routes
    const routes = data.routes ? data.routes : []
    // Register new router
    router = new Router( {
      ...options,
      linkExactActiveClass: 'is-active',
      parseQuery,
      routes: [
        {
          path: '/',
          component: Component,
          name: 'index-wl',
          children: [
            {
              path: '/yleiset-ehdot',
              component: Vue.extend( require( '~/whitelabels/pages/yleiset-ehdot' ).default ),
              name: 'yleiset-ehdot'
            },
            ...routes
          ]
        }
      ]
    } )
  }
  else {
    router = new Router( {
      ...options,
      linkExactActiveClass: 'is-active',
      parseQuery,
      routes: [
        ...options.routes,
        {
          name: 'hae-lainaa',
          path: '/hae-lainaa',
          component: YhdistaLainat
        },
        {
          name: 'poismaksu',
          path: '/poismaksu',
          component: YhdistaLainat
        }
      ]
    } )
    // Get current routes for thousands
    const thousands = router.options.routes
      .filter( route => /lainaa-\d*-euroa/.test( route.name ) )
      .map( route => parseInt( route.name.split( '-' )[1], 10 ) )
    // Create missing routes for thousands
    for ( let index = 10; index < 50; index++ ) {
      const pageNumber = index * 1000
      if ( thousands.includes( pageNumber ) ) {
        continue
      }
      router.addRoute( {
        name: `lainaa-${pageNumber}-euroa`,
        path: `/lainaa-${pageNumber}-euroa`,
        component: Lainaa11000Euroa
      } )
    }
    /**
     * Business loanpages.
     * 5000 - 25000 (1000)
     * 30000 - 100 000 (5000)
     * 110 000 - 300 000 (10 000)
     * 350 000 - 1 500 000 (50 000)
     * 2 000 000 - 8 000 000 (500 000)
     */
    for ( let index = 6; index <= 8000; index++ ) {
      const pageNumber = index * 1000
      router.addRoute( {
        name: `yrityslainaa-${pageNumber}-euroa`,
        path: `/yrityslainaa-${pageNumber}-euroa`,
        component: YrityslainaaXXEuroa
      } )
      if ( index >= 1500 ) {
        index += 499
      }
      else if ( index >= 300 ) {
        index += 49
      }
      else if ( index >= 100 ) {
        index += 9
      }
      else if ( index >= 25 ) {
        index += 4
      }
    }
    // Add strapi routes
    router.addRoute( {
      name: 'strapi',
      path: '/:strapi',
      component: Strapi,
      props: {
        path: 'pages'
      }
    } )
    router.addRoute( {
      name: 'strapi_article',
      path: '/talous/:strapi',
      component: Strapi,
      props: {
        path: 'articles'
      }
    } )
  }
  return router
}
