/**
 * Affiliate utility
 *
 * @export
 * @param {object} [query={}]
 * @param {Cookie} cookie
 */
export default function affiliate ( query = {}, cookie ) {
  if ( query.arrive ) {
    cookie.set( 'RL_arrive', query.arrive )
    cookie.set( 'RL_keyword', query.keyw || query.keyword || '' )
  }
  // Common tracking
  let fp = ''
  let fp2 = ''
  let fp3 = ''
  let fp4 = ''
  let fp5 = ''
  if ( query.fp ) {
    fp = query.fp
  }
  if ( query.fp2 ) {
    fp2 = query.fp2
  }
  if ( query.fp3 ) {
    fp3 = query.fp3
  }
  if ( query.fp4 ) {
    fp4 = query.fp4
  }
  if ( query.fp5 ) {
    fp5 = query.fp5
  }
  // Adservice
  if ( query.AffId ) {
    fp2 = query.AffId
  }
  // Adservice CPS
  if ( query.coid ) {
    fp = query.coid
  }
  // Adtraction
  if ( query.cn && query.cv ) {
    fp = query.cn
    fp2 = query.cv
  }
  if ( query.at_gd ) {
    fp = query.at_gd
  }
  if ( query.pid && query.mid ) {
    fp4 = query.mid
    fp3 = query.pid
  }
  // EuroAds ID
  if ( query.euroadsid ) {
    fp = query.euroadsid
  }
  // Tradetracker
  if ( query.data ) {
    fp = query.data
  }
  // WeLoveLeads
  if ( query.or_uuid ) {
    fp = query.or_uuid
  }
  // NetBooster
  if ( query.clickid ) {
    fp = query.clickid
  }
  // SmartResponse
  if ( query.reqid ) {
    fp = query.reqid
  }
  // AdsNinja
  if ( query.subid ) {
    fp = query.subid
  }
  // DoubleResults
  if ( query.dr_id ) {
    fp = query.dr_id
  }
  // DraiviMedia
  if ( query.tid ) {
    fp = query.tid
  }
  // Performission
  if ( query.tmtData ) {
    fp = query.tmtData
  }
  // Digital Advisor
  if ( query.transaction_id ) {
    fp = query.transaction_id
  }
  // Orion Media
  if ( query['click-id'] ) {
    fp = query['click-id']
  }
  // Circlewise
  if ( query['click_id'] ) {
  fp = query['click_id']
  }
  if ( fp ) {
    cookie.set( 'RL_arrive_fp', fp )
  }
  if ( fp2 ) {
    cookie.set( 'RL_arrive_fp2', fp2 )
  }
  if ( fp3 ) {
    cookie.set( 'RL_arrive_fp3', fp3 )
  }
  if ( fp4 ) {
    cookie.set( 'RL_arrive_fp4', fp4 )
  }
  if ( fp5 ) {
    cookie.set( 'RL_arrive_fp5', fp5 )
  }
}
