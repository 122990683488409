/**
 * ConsoleDriver class for handling errors
 *
 * @class ConsoleDriver
 */
class ConsoleDriver {
  /**
   * Console log message
   *
   * @param {any} args
   * @memberof this
   */
  notify ( ...args ) {
    const opts = args[1] || {}
    if ( opts.severity === 'info' ) {
      this.info( ...args )
    }
    else if ( opts.severity === 'warning' ) {
      this.warning( ...args )
    }
    else if ( opts.severity === 'error' ) {
      this.error( ...args )
    }
    else {
      console.log( ...args )
    }
  }

  /**
   * Console info message
   *
   * @param {any} args
   * @memberof ConsoleDriver
   */
  info ( ...args ) {
    console.info( ...args )
  }

  /**
   * Console warning message
   *
   * @param {any} args
   * @memberof ConsoleDriver
   */
  warning ( ...args ) {
    console.warn( ...args )
  }

  /**
   * Console error message
   *
   * @param {any} args
   * @memberof ConsoleDriver
   */
  error ( ...args ) {
    console.error( ...args )
  }
}

export default ConsoleDriver
