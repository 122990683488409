import { loadDynamicScript } from '../loader'

export function load () {
  if ( this.app.$config.RELEASE_STAGE !== 'production' ) {
    const clone = ( ...args ) => console.log( 'GoogleAsync:', ...args )
    window.google_trackConversion = clone
    return
  }
  loadDynamicScript( '//www.googleadservices.com/pagead/conversion_async.js' )
}

export function onApplicationSent ( _form ) {
  if ( typeof window.google_trackConversion === 'function' ) {
    try {
      window.google_trackConversion( {
        google_conversion_id: 941462017,
        google_conversion_language: 'en',
        google_conversion_color: 'ffffff',
        google_conversion_label: 'RNmOCPS9wGAQgaT2wAM',
        google_remarketing_only: false,
        google_conversion_format: 3
      } )
      return true
    }
    catch ( error ) {
      if ( error.message === 'window.google_trackConversion is not a function' ||
        error.message === "Object doesn't support property or method 'google_trackConversion'" ||
        error.message === 'Objekti ei tue ominaisuutta tai menetelmää google_trackConversion' ||
        error.message.startsWith( 'undefined is not a function' )
      ) {
        // Ignore error, probably adblock
        return
      }
      this.app.$logger.error( error )
    }
  }
  return false
}
