<template>
  <div>
    <navbar :is-spaced="false" />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application @sent="loadThankYouPixels" />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp />
    </lazy-hydrate>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-149382832-1" />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  layout: 'whitelabel',
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'Eliittilaina.fi -palvelun avulla voit hakea uutta lainaa tai kilpailuttaa jo olemassa olevan lainasi uudelleen. Eliittilaina  kilpailuttaa 100 – 70.000 euron suuruiset lainat puolestasi ja tarjoaa aina sinulle parhaan lainavaihtoehdon.'
    const theme = '#172638'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/eliittilaina/favicon.png' ) }]
    }
  },
  data () {
    return {
      name: 'Eliittilaina',
      domain: 'eliittilaina.fi'
    }
  },
  mounted () {
    // Do not load on development
    if ( this.$config.RELEASE_STAGE !== 'production' ) return
    // Load facebook pixel (Delete RL console first)
    delete window.fbq
    /* eslint-disable */
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', '680377435343394');
    window.fbq('track', 'PageView');
    window.fbq('track', 'Lead');
    // Load google tag manager
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'UA-149382832-1');
  },
  methods: {
    loadThankYouPixels (formData) {
      if (this.$config.RELEASE_STAGE !== 'production') return
      window.fbq('track', 'Purchase')
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: rgb(230, 64, 54);
$wl-body-color: rgb(0, 32, 78);
$wl-body-background: url(//d9hhrg4mnvzow.cloudfront.net/www.eliittilaina.fi/800652d5-shutterstock-366918614-1_1hc0zk1hc0vw00001u01o.jpg);

$wl-navbar-background: #fff;
$wl-navbar-height: 8.2rem;
$wl-navbar-logo-height: 6rem ;

$wl-app-background: rgba(255,255,255,0.82);
$wl-app-border: 10px solid #b9944d;
$wl-app-border-radius: 0px;
$wl-interest-rate-bg: transparent;
$wl-interest-rate-text: $wl-body-color;

$wl-slider-height: 4px;
$wl-slider-top: 14px;
$wl-slider-handle-color: rgb(238, 238, 238);
$wl-slider-handle-border: 2px solid rgb(230, 64, 54);
$wl-slider-handle-size: 20px;
$wl-slider-handle-top: -8px;

$wl-submit-button-border-radius: 0px;

$wl-footer-color: white;
$wl-footer-background: linear-gradient(#2d2d2d,#030303);
$wl-footer-logo-background: white;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.eliittilaina {
  @include whitelabel;
}
</style>
