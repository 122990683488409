<template>
  <div class="subpage">
    <navbar />
    <section class="hero main-hero">
      <div class="hero-body">
        <div class="container">
          <nuxt />
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <subpages />
      </div>
    </section>
    <section class="section">
      <div class="container">
        <terms loan-type="business" />
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
    <hero-action />
    <lazy-hydrate ssr-only>
      <footer-comp />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/business/banks' ),
    footerComp: () => import( '~/components/layout/footer' ),
    heroAction: () => import( '~/components/layout/heroAction' ),
    navbar: () => import( '~/components/layout/navbar' ),
    subpages: () => import( '~/components/layout/subpages' ),
    terms: () => import( '~/components/layout/terms' )
  },
  head () {
    const result = {
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi' + this.$route.path }
      ]
    }

    if ( ['www2.rahalaitos.fi', 'rahalaitos-v2.local'].includes( this.$domain ) ) {
      result.meta = [{ hid: 'robots', name: 'robots', content: 'noindex' }]
    }

    return result
  }
}
</script>

<style lang="scss">
@import "~assets/scss/subpage.scss";
</style>
