import createPersistedState from 'vuex-persistedstate/src/index.ts'
import Storage from '~/utils/storage/Universal'

export default ( { store, req, res, isDev, $logger } ) => {
  const storage = new Storage( req, res, {
    cookie: {
      path: '/',
      maxAge: 60 * 60 * 24 * 7,
      secure: !isDev
    }
  },
  $logger )
  createPersistedState( {
    paths: [
      'auth.status'
    ],
    storage: {
      getItem: key => storage.get( key ),
      setItem: ( key, value ) => storage.set( key, value ),
      removeItem: key => storage.remove( key )
    },
    getState: ( key, storage ) => storage.getItem( key )
  } )( store )
}
