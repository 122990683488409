import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { event } from 'vue-analytics'
import { event as gtagEvent } from 'vue-gtag'
import { RootState } from '~/store'

export const state = () => ( {
  result: {},
  status: false as false | Number,
  hasOutpaid: false
} )

export type AuthState = ReturnType<typeof state>

export const mutations: MutationTree<AuthState> = {
  STATUS ( state, status ) {
    state.status = status
  },
  RESULT ( state, result = {} ) {
    state.result = result
  },
  LOGOUT ( state ) {
    state.status = false
  },
  HASOUTPAID ( state, value ) {
    state.hasOutpaid = value
  }
}

export const actions: ActionTree<AuthState, RootState> = {
  /**
   * Set current status
   */
  async status ( { commit }, status ) {
    await commit( 'STATUS', status === false ? false : parseInt( status, 10 ) )
  },
  /**
   * Do login action - send SSN to server
   */
  async login ( { commit, dispatch }, username ) {
    await commit( 'RESULT', 'loading' )
    try {
      const result = await this.$axios.$post( '/v1/oma/?action=login', { username } )
      await dispatch( 'result', result )
    }
    catch ( error ) {
      await commit( 'RESULT', 'error' )
      this.app.$logger.error( error )
    }
  },
  /**
   * Do password action - send password to server
   */
  async password ( { commit, dispatch }, password ) {
    await commit( 'RESULT', 'loading' )
    try {
      const result = await this.$axios.$post( '/v1/oma/?action=password', {
        password: password.trim()
      } )
      await dispatch( 'result', result )
    }
    catch ( error ) {
      await commit( 'RESULT', 'error' )
      this.app.$logger.error( error )
    }
  },
  /**
   * Do password action - send logout request to server
   */
  async logout ( { commit } ) {
    event( { eventCategory: 'Oma', eventAction: 'Logout' } )
    ;( window as any )._giosg( 'visitor', 'removeAll' )
    try {
      await this.$axios.$post( '/v1/oma/?action=logout' )
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
    await commit( 'LOGOUT' )
  },
  /**
   * Store result into Vuex store
   */
  async result ( { commit }, result ) {
    if ( Object.prototype.hasOwnProperty.call( result, 'Status' ) ) {
      await commit( 'STATUS', result.Status )
      // Track error to GA
      if ( result.Status === 9001 || result.Status === 9002 ) {
        event( { eventCategory: 'Oma', eventAction: 'showError', eventLabel: result.Status } )
      }
      else if ( result.Status === 2 ) {
        gtagEvent( 'logged_in', { event_category: 'Oma' } )
      }
    }
    await commit( 'RESULT', result )
  },
  /**
   * Call resend password action
   */
  async resend ( { commit, dispatch } ) {
    await commit( 'RESULT', 'loading' )
    try {
      const result = await this.$axios.$post( '/v1/oma/?action=resend' )
      await dispatch( 'result', result )
    }
    catch ( error ) {
      await commit( 'RESULT', 'error' )
      this.app.$logger.error( error )
    }
  }
}

export const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn: state => state.status === 2 || state.status === 3
}
