import Cookie from 'cookie-universal'
import _ from 'lodash'
import { parse as parseCookie, serialize as serializeCookie } from 'cookie'

/**
 * Universal storage utility
 *
 * @see https://github.com/nuxt-community/auth-module/blob/master/lib/core/storage.js
 * @export
 * @class Storage
 */
export default class Universal {
  constructor ( req, res, options, $logger ) {
    this.req = req
    this.res = res
    this.options = Object.assign( {
      prefix: '',
      localStorage: true,
      cookie: {
        options: {}
      }
    }, options )
    this.cookie = Cookie( req, res )
    this.$logger = $logger
  }

  // ------------------------------------
  // Universal
  // ------------------------------------

  set ( key, value ) {
    // Unset null, undefined
    if ( this.isUnset( value ) ) {
      return this.remove( key )
    }
    this.setCookie( key, value )

    this.setLocalStorage( key, value )

    return value
  }

  get ( key ) {
    let value = this.getCookie( key )

    if ( this.isUnset( value ) ) {
      value = this.getLocalStorage( key )
    }

    return value
  }

  sync ( key, defaultValue ) {
    let value = this.get( key )

    if ( this.isUnset( value ) && this.isSet( defaultValue ) ) {
      value = defaultValue
    }

    if ( this.isSet( value ) ) {
      this.set( key, value )
    }

    return value
  }

  remove ( key ) {
    this.removeLocalStorage( key )
    this.removeCookie( key )
  }

  // ------------------------------------
  // Local storage
  // ------------------------------------

  setLocalStorage ( key, value ) {
    // Unset null, undefined
    if ( this.isUnset( value ) ) {
      return this.removeLocalStorage( key )
    }

    if ( typeof localStorage === 'undefined' || !this.options.localStorage ) {
      return
    }

    const _key = this.options.prefix + key

    try {
      localStorage.setItem( _key, this.encodeValue( value ) )
    }
    catch ( e ) {
      if ( !this.options.ignoreExceptions ) {
        throw e
      }
    }

    return value
  }

  getLocalStorage ( key ) {
    if ( typeof localStorage === 'undefined' || !this.options.localStorage ) {
      return
    }

    const _key = this.options.prefix + key

    const value = localStorage.getItem( _key )

    return this.decodeValue( value )
  }

  removeLocalStorage ( key ) {
    if ( typeof localStorage === 'undefined' || !this.options.localStorage ) {
      return
    }

    const _key = this.options.prefix + key
    localStorage.removeItem( _key )
  }

  // ------------------------------------
  // Cookies
  // ------------------------------------

  getCookies () {
    const cookieStr = process.client
      ? document.cookie
      : this.req.headers.cookie

    return parseCookie( cookieStr || '' ) || {}
  }

  setCookie ( key, value, options = {} ) {
    if ( !this.options.cookie || ( process.server && !this.res ) ) {
      return
    }

    const _key = this.options.prefix + key
    const _options = _.merge( {}, this.options.cookie, options )
    const _value = this.encodeValue( value )

    // Unset null, undefined
    if ( this.isUnset( value ) ) {
      _options.maxAge = -1
    }

    // Accept expires as a number for js-cookie compatiblity
    if ( typeof _options.expires === 'number' ) {
      _options.expires = new Date( new Date() * 1 + _options.expires * 864e+5 )
    }

    const serializedCookie = serializeCookie( _key, _value, _options )

    if ( process.client ) {
      // Set in browser
      document.cookie = serializedCookie
    }
    else if ( process.server && this.res ) {
      // Send Set-Cookie header from server side
      const prevCookies = this.res.getHeader( 'Set-Cookie' )
      this.res.setHeader( 'Set-Cookie', [].concat( prevCookies, serializedCookie ).filter( v => v ) )
    }
    return value
  }

  getCookie ( key ) {
    if ( !this.options.cookie || ( process.server && !this.req ) ) {
      return
    }

    const _key = this.options.prefix + key

    const cookies = this.getCookies()
    let value = cookies[_key]
    try {
      value = decodeURIComponent( cookies[_key] )
    }
    catch ( error ) {
      this.$logger.error( error )
    }

    return this.decodeValue( value )
  }

  removeCookie ( key, options ) {
    this.setCookie( key, undefined, options )
  }

  // ------------------------------------
  // Utilities
  // ------------------------------------

  isUnset ( o ) {
    return _.isEmpty( o )
  }

  isSet ( o ) {
    return !this.isUnset( o )
  }

  encodeValue ( val ) {
    if ( typeof val === 'string' ) {
      return val
    }
    return JSON.stringify( val )
  }

  decodeValue ( val ) {
    // Try to parse as json
    if ( typeof val === 'string' ) {
      try {
        return JSON.parse( val )
      }
      catch ( _ ) { }
    }

    // Return as is
    return val
  }
}
