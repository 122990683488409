<template>
  <div class="columns is-centered">
    <div class="column is-half">
      <div class="content app">
        <div class="error">
          <div class="title is-6">Tapahtui odottamaton virhe, mikäli virhe toistuu kehoitamme olemaan yhteydessä asiakaspalveluumme</div>
          <div>{{ message }}</div>
          <p v-if="statusCode === 404" class="description">
            <nuxt-link to="/" type="button" class="button is-primary is-rounded" style="margin-top: 2em;">Palaa etusivulle</nuxt-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  head () {
    const result = {
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi' + this.$route.path }
      ],
      title: `Rahalaitos | ${this.message}`
    }

    if ( ['www2.rahalaitos.fi', 'rahalaitos-v2.local'].includes( this.$domain ) ) {
      result.meta = [{ hid: 'robots', name: 'robots', content: 'noindex' }]
    }

    return result
  },
  computed: {
    statusCode () {
      return ( this.error && this.error.statusCode ) || 500
    },
    message () {
      let error = this.error.message
      if ( error === 'This page could not be found' ) error = 'Emme valitettavasti löytäneet hakemaanne sivua.'
      return error
    }
  }
}
</script>
