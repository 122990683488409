<template>
  <div>
    <navbar :is-spaced="false" />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  layout: 'whitelabel',
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'Lainavertailija vertailee vuoden 2018 parhaimmat ja halvimmat lainat ja kulutusluotot. Ilmainen palvelu, jonka avulla löydät juuri itsellesi parhaimman vaihtoehdon.'
    const theme = '#414c5f'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/lainavertailija/favicon.png' ) }]
    }
  },
  data () {
    return {
      name: 'Lainavertailija',
      domain: 'lainavertailija.fi'
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: rgba(66, 77, 96, 0.9);
$wl-body-background: url("https://images.unsplash.com/photo-1472573973708-b55521f3bdc0?dpr=1&auto=compress,format&fit=crop&w=1959&h=&q=80&cs=tinysrgb&crop=");

$wl-navbar-background: #fff;
$wl-navbar-height: 4.8rem;
$wl-navbar-logo-height: 4rem;

$wl-slider-handle-border: 2px solid white;

$wl-footer-color: white;
$wl-footer-background: #1a1b1f;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.lainavertailija {
  @include whitelabel;
}
</style>
