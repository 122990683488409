export function load () {
  if ( this.app.$config.RELEASE_STAGE !== 'production' && this.app.$config.RELEASE_STAGE !== 'staging' ) {
    const clone = ( ...args ) => console.log( 'Giosg:', ...args )
    window._giosg = clone
    return
  }
  /* eslint-disable */
  (function (w, t, f) {
    var s = 'script'; var o = '_giosg'; var h = 'https://service.giosg.com'; var e; var  n; e = t.createElement(s); e.defer = 1; e.src = h + '/live/'
    w[o] = w[o] || function () { (w[o]._e = w[o]._e || []).push(arguments) }; w[o]._c = f; w[o]._h = h; n = t.getElementsByTagName(s)[0]; n.parentNode.insertBefore(e, n)
  })(window, document, 4621)
  /* eslint-enable */

  try {
    // Poista giosg tunnistetiedot jos asiakas ei ole kirjautunut
    if ( !this.app.store.state.onload.chat ) {
      window._giosg( 'visitor', 'removeAll' )
    }
    // Lähetä user agent
    window._giosg( 'visitor', 'submit', { userAgent: navigator.userAgent } )
  }
  catch ( error ) {
    // Ignore error
  }
}

export function onIncompleteSent ( _form ) {
  if ( typeof window._giosg === 'function' ) {
    try {
      window._giosg( 'goal', 'matches', 'ff0dc0b4-90c1-11e8-96fc-0242ac110009' )
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
  }
  return false
}

export function onApplicationSent ( form ) {
  if ( typeof window._giosg === 'function' ) {
    try {
      window._giosg( 'goal', 'matches', '5ec6b33a-90c2-11e8-b254-0242ac110004' )
      window._giosg( function () {
        window.giosg.api.shoppingCart.submit( [{
          name: 'loan',
          price: form.luottoraja,
          quantity: 1,
          monthly_plan_length: form.maksuaika
        }] )
      } )
      return true
    }
    catch ( error ) {
      if ( error.name === 'SecurityError' ) {
        // Ignore error, probably adblock
        return
      }
      this.app.$logger.error( error )
    }
  }
  return false
}
