<template>
  <footer class="footer has-background-rlgray">
    <div class="container">
      <div v-if="content.length" class="columns is-multiline">
        <div v-for="(item, index) in content" :key="index" class="column is-4-desktop">
          <p class="footer-link-title">{{ item.title }}</p>
          <p v-for="(row, rowIndex) in item.content" :key="rowIndex" class="footer-link">{{ row }}</p>
        </div>
      </div>
      <div class="columns">
        <div class="column has-vertical-center">
          {{ toUnicode($store.state.whitelabel.domain) | uppercase }} © {{ new Date().getFullYear() }} | KAIKKI OIKEUDET PIDÄTETÄÄN
        </div>
        <div v-if="logo !== 'false'" class="column is-narrow footer-wl-logo">
          <img :src="require(`~/assets/whitelabels/${$store.state.whitelabel.site}/${logo}`)">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { toUnicode } from 'punycode'

export default {
  filters: {
    uppercase: function ( value ) {
      if ( !value ) return ''
      return value.toString().toUpperCase()
    }
  },
  props: {
    content: {
      type: Array,
      default: () => []
    },
    logo: {
      type: String,
      default: 'logo.png'
    }
  },
  methods: {
    toUnicode
  }
}
</script>

<style lang="scss">
.whitelabel {
  .footer {
    img {
      max-height: 4rem;
      max-width: 16rem;
    }
  }
}
</style>
