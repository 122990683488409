import { FinnishSSN } from 'finnish-ssn'
import {
  Validator, install as VeeValidate
} from 'vee-validate/dist/vee-validate.minimal.esm.js'
import {
  // eslint-disable-next-line camelcase
  alpha_spaces,
  // eslint-disable-next-line camelcase
  alpha_num,
  digits,
  email,
  length,
  max,
  min,
  numeric,
  required
} from 'vee-validate/dist/rules.esm.js'
import Vue from 'vue'

/**
 * Add Finnish localization
 */
Validator.localize( 'fi', {
  name: 'fi',
  messages: {
    _default: field => `${field} ei ole validi.`,
    alpha_spaces: field => `${field} voi sisältää vain aakkosmerkkejä sekä välilyöntejä.`,
    digits: ( field, [length] ) => `${field} tulee olla numeerinen ja tarkalleen ${length} merkkiä.`,
    length: ( field, [length, max] ) => {
      if ( max ) {
        return `${field} saa olla vähintään ${length} ja enintään ${max}.`
      }
      return `${field} pitää olla ${length} merkkiä.`
    },
    max: ( field, [length] ) => `${field} ei saa olla pidempi kuin ${length} merkkiä.`,
    min: ( field, [length] ) => `${field} tulee olla vähintään ${length} merkkiä.`,
    numeric: field => `${field} voi sisältää vain numeroita.`,
    required: field => `${field} on pakollinen kenttä.`
  },
  custom: {
    nettotulot: {
      less_than: 'Nettotulot kuukaudessa pitää olla pienemmät tai sama kuin bruttotulot kuukaudessa'
    },
    bruttotulot: {
      more_than: 'Bruttotulot kuukaudessa pitää olla suuremmat tai sama kuin nettotulot kuukaudessa'
    }
  }
} )

/**
 * Add default rules
 */
Validator.extend( 'alpha_spaces', alpha_spaces )
Validator.extend( 'alpha_num', alpha_num )
Validator.extend( 'digits', digits )
Validator.extend( 'email', email )
Validator.extend( 'length', length )
Validator.extend( 'max', max )
Validator.extend( 'min', min )
Validator.extend( 'numeric', numeric )
Validator.extend( 'required', required )

/**
 * Min stripped rule is used to compare a value against minimum one.
 * Difference for
 */
Validator.extend( 'min_value_stripped', {
  getMessage: ( field, [param] ) => `${field} täytyy olla vähintään ${param} euroa`,
  validate: ( value, [param] ) => {
    if ( value === null || value === undefined || value === '' ) {
      return false
    }
    if ( typeof value === 'string' ) {
      value = Number( value.replace( /[^0-9]/g, '' ) )
    }
    return value >= param
  }
}, {
  immediate: false
} )

/**
 * Min stripped rule is used to compare a value against minimum one.
 * Difference must be defined percentage
 */
Validator.extend( 'percentage_difference', {
  getMessage: ( field, [target, percentage], date ) => 'Tarkista netto/bruttotulosi',
  validate: ( value, { target, percentage } ) => {
    if ( typeof value === 'string' ) {
      value = parseInt( value.replace( /[^0-9]/g, '' ), 10 )
    }
    if ( typeof target === 'string' ) {
      target = parseInt( target.replace( /[^0-9]/g, '' ), 10 )
    }
    return Math.round( Math.min( value, target ) / Math.max( value, target ) * 100 ) >= percentage
  }
}, { paramNames: ['target', 'percentage'] } )

/**
 * Value must be smaller than target value
 */
Validator.extend( 'less_than', {
  getMessage: ( field, [target] ) => `${field} pitää olla pienempi tai sama kuin ${target}`,
  validate: ( value, [target] ) => {
    if ( typeof value === 'string' ) {
      value = parseInt( value.replace( /[^0-9]/g, '' ), 10 )
    }
    if ( typeof target === 'string' ) {
      target = parseInt( target.replace( /[^0-9]/g, '' ), 10 )
    }
    return parseInt( value, 10 ) <= parseInt( target, 10 )
  }
}, { hasTarget: true } )

/**
 * Value must be larger than target value
 */
Validator.extend( 'more_than', {
  getMessage: ( field, [target] ) => `${field} pitää olla suurempi tai sama kuin ${target}`,
  validate: ( value, [target] ) => {
    if ( typeof value === 'string' ) {
      value = parseInt( value.replace( /[^0-9]/g, '' ), 10 )
    }
    if ( typeof target === 'string' ) {
      target = parseInt( target.replace( /[^0-9]/g, '' ), 10 )
    }
    return value >= target
  }
}, { hasTarget: true } )

/**
 * Validates finnish SSN
 */
Validator.extend( 'finnish_ssn', {
  getMessage: () => 'Tarkista, että olet kirjoittanut henkilötunnuksesi oikein. Esim. 123456-123A',
  validate: value => FinnishSSN.validate( value )
} )

/**
 * Validates min age from Finnish SSN
 * NOTICE: Use after finnish_ssn rule
 */
Validator.extend( 'age_min', {
  getMessage: ( field, min ) => `Hakijan täytyy olla iältään ${min} -vuotias`,
  validate: ( value, min ) => FinnishSSN.parse( value ).ageInYears >= min
} )

/**
 * Validates max age from Finnish SSN
 * NOTICE: Use after finnish_ssn rule
 */
Validator.extend( 'age_max', {
  getMessage: ( field, max ) => `Hakijan täytyy olla iältään alle ${max} -vuotias`,
  validate: ( value, max ) => FinnishSSN.parse( value ).ageInYears <= max
} )

/**
 * Value cannot be same as target
 */
Validator.extend( 'not_same', {
  getMessage: ( field, [target] ) => `${field} ei voi olla sama kuin ${target}`,
  validate: ( value, [target] ) => value !== target
}, { hasTarget: true } )

/**
 * Strips non numeric values and validates that length is between 5, 15
 */
Validator.extend( 'phone_stripped', {
  getMessage: _field => 'Virheellinen puhelinnumero',
  validate: value => /^(\d{5,15})$/.test( value.replace( /([^\d])/g, '' ) )
} )

/**
 * Returns false with custom error message when value matches
 * @example false_if:,'Ei työsuhdetta','Työtön ei voi hakea'
 * @example false_if:['Ei työsuhdetta', 'Eläkeläinen'], 'Et voi hakea tällä työsuhteella'
 */
Validator.extend( 'false_if', {
  getMessage: ( field, params, data ) => ( data && data.message ) || 'Valinta on virheellinen',
  validate: ( value, [fieldValue, message] ) => {
    const falseResponse = {
      valid: false,
      data: {
        message
      }
    }
    // If first param is in array return false
    if ( Array.isArray( fieldValue ) && fieldValue.includes( value ) ) {
      return falseResponse
    }
    // If value matches parameter return false
    if ( fieldValue === value ) {
      return falseResponse
    }
    return true
  }
} )

/**
 * Returns false with customer error message when value matches with age expression
 * @example false_if_age_and_value:,'< 60','Eläkeläinen','Alle 60v eläkeläinen ei voi hakea'
 */
Validator.extend( 'false_if_age_and_value', {
  getMessage: ( field, params, data ) => ( data && data.message ) || 'Valinta on virheellinen',
  validate: ( value, [target, expression, fieldValue, message] ) => {
    if ( value === fieldValue ) {
      const [operator, operatorValue] = expression.split( ' ' )
      const age = FinnishSSN.parse( target ).ageInYears
      const comparisons = {
        '<' ( a, b ) {
          return a < b
        },
        '<=' ( a, b ) {
          return a <= b
        },
        '>' ( a, b ) {
          return a > b
        },
        '>=' ( a, b ) {
          return a >= b
        },
        '===' ( a, b ) {
          return a === b
        },
        '!==' ( a, b ) {
          return a !== b
        }
      }
      return {
        valid: !comparisons[operator]( age, operatorValue ),
        data: {
          message
        }
      }
    }
    return true
  }
}, { hasTarget: true } )

/**
 * Value should not be adjacent or same with the target value
 * Only works with numeric values
 */
Validator.extend( 'not_adjacent', {
  getMessage: ( field, [target], data ) => `Ei peräkkäisiä ${( data && data.message ) || target}`,
  validate: ( value, [target, message] ) => {
    const result = Math.abs( value - target )
    return {
      valid: target === '' || ( result > 1 && result !== 11 ),
      data: {
        message
      }
    }
  }
}, { hasTarget: true } )

/**
 * Only allows spaces and numbers
 */
Validator.extend( 'numeric_spaces', {
  getMessage: () => 'Syötä pelkästään kokonaislukuja',
  validate: ( value ) => {
    if ( Array.isArray( value ) ) {
      return value.every( val => /^[0-9 ]+$/.test( String( val ) ) )
    }

    return /^[0-9 ]+$/.test( String( value ) )
  }
}, { hasTarget: true } )

/**
 * Validate finnish business ID
 */
Validator.extend( 'business', {
  getMessage: () => 'Y-tunnus on virheellinen',
  validate: ( value ) => {
    // Basic regex validation
    if ( !value.match( /^\d{6,7}-\d$/ ) ) {
      return false
    }
    let idData = value.split( '-' )[0]
    // Some old company id's have only 6 digits. They should be prefixed with 0.
    if ( idData.length === 6 ) {
      idData = '0' + idData
    }
    // Function for greating checksum
    const buildCheckSum = ( data ) => {
      const multi = [7, 9, 10, 5, 8, 4, 2]
      const pad = value => Math.ceil( value / 11 ) * 11 - value
      return pad( data.map( ( value, idx ) => multi[idx] * value ).reduce( ( prev, curr ) => prev + curr ) ) % 11
    }
    // Build new BusinessID
    const buildedID = idData + '-' + buildCheckSum( idData.split( '' ).map( c => parseInt( c, 10 ) ) )
    // Compare that checksum matches
    return buildedID === value
  }
} )

/**
 * Allow multiple strict lengths
 */
Validator.extend( 'lengths', {
  getMessage: ( _fieldName, lengths ) => `Kentän merkkimäärä täytyy olla: ${lengths}`,
  validate: ( value, lengths ) => {
    if ( value && lengths ) {
      if ( lengths.includes( String( value.length ) ) ) {
        return true
      }
    }

    return false
  }
} )
/**
 * Insert validator into Vue
 */
Vue.use( VeeValidate, {
  inject: false,
  locale: 'fi',
  events: 'change'
} )
