export default ( { _app, store, req }, _inject ) => {
  const host = req ? req.headers.host : window.location.host.split( ':' )[0]
  // Subdomain whitelabel
  if ( ['hakemus', 'app', 'lainakone', 'kulutusluotot', 'hae', 'lainahakemus'].includes( host.split( '.' )[0] ) ) {
    const site = host.split( '.' )[1]
    const Whitelabel = require( `~/whitelabels/${site}` ).default
    const data = Whitelabel.data()
    // Register whitelabel Vuex store
    store.registerModule( 'whitelabel', {
      namespaced: true,
      state: {
        domain: data.domain,
        hasServiceDescription: data.hasServiceDescription || false,
        name: data.name,
        site,
        unemployedRedirect: undefined
      },
      mutations: {
        UNEMPLOYED_REDIRECT ( state, url ) {
          state.unemployedRedirect = url
        }
      }
    }, {
      preserveState: process.client
    } )
  }
  // Check if custom iframe whitelabel
  else {
    const path = req ? req.url : window.location.pathname
    const isIframe = path.match( /^(?:\/(iframe))(?:\/([\w]+))?/ ) // regexr.com/5nkrg

    if ( isIframe ) {
      store.registerModule( 'whitelabel', {
        namespaced: true,
        state: {
          site: isIframe.pop(),
          unemployedRedirect: undefined,
          iframe: true
        },
        mutations: {
          UNEMPLOYED_REDIRECT ( state, url ) {
            state.unemployedRedirect = url
          }
        }
      }, {
        preserveState: process.client
      } )
    }
  }
}
