<template>
  <nav :class="{'is-spaced': isSpaced}" class="navbar is-transparent has-text-weight-bold">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item" :href="`https://${$store.state.whitelabel.domain}`" :aria-label="`${$store.state.whitelabel.name}`">
          <template v-if="logo !== 'false'">
            <img :src="require(`~/assets/whitelabels/${$store.state.whitelabel.site}/${logo}`)">
          </template>
          <p v-else-if="text" class="logo-text" v-html="text" />
          <p v-else>{{ $store.state.whitelabel.domain }}</p>
        </a>
      </div>
      <slot />
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    isSpaced: {
      type: Boolean,
      default: true
    },
    logo: {
      type: String,
      default: 'logo.png'
    },
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
