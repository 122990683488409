export function load () {
  if ( this.app.$config.RELEASE_STAGE !== 'production' ) {
    const clone = ( ...args ) => console.log( 'Bing:', ...args )
    window.uetq = clone
    window.uetq.push = clone
    return
  }
  /* eslint-disable */
  try {
    (function(w, d, t, r, u) {
      var f, n, i;
      w[u] = w[u] || [], f = function() {
          var o = {
              ti: "5528167"
          };
          o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad")
      }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function() {
          var s = this.readyState;
          s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)
      }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
    })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
  } catch (error) {
    if (error.message === 'UET is not defined' || error.message === '\'UET\' is not defined') {
      // Ignore error probably adblock
      return
    }
    this.app.$logger.error(error)
  }
  /* eslint-enable */
}

export function onApplicationSent ( form ) {
  if ( typeof window.uetq !== 'undefined' ) {
    try {
      window.uetq.push( {
        ec: 'Rahalaitos',
        ea: 'Show',
        el: 'ThankYouPage',
        gv: form.luottoraja,
        gc: 'EUR'
      } )
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
  }
  return false
}
