/**
 * Plugin for handling font awesome 5 in nuxt project
 * @author Alan Aasmaa <alan.aasmaa@eone.fi>
 */
import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  faCommentAltSmile,
  faFile,
  faLockAlt,
  faMinus,
  faPlus,
  faShoppingCart,
  faTrashAlt,
  faUserAlt
} from '@fortawesome/pro-light-svg-icons'
import {
  faEuroSign,
  faLayerPlus,
  faMinusCircle,
  faPlusCircle,
  faSlidersH,
  faUserCircle,
  faCheck
} from '@fortawesome/pro-regular-svg-icons'
import {
  faAngleRight,
  faAngleUp,
  faArrowRight,
  faInfoCircle,
  faPhone,
  faQuestionSquare,
  faSignOut,
  faSort,
  faSpinnerThird,
  faStar,
  faStarHalfAlt,
  faSyncAlt
} from '@fortawesome/pro-solid-svg-icons'
import {
  faCcMastercard,
  faFacebookSquare,
  faTwitterSquare
} from '@fortawesome/free-brands-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

library.add(
  faAngleRight,
  faAngleUp,
  faArrowRight,
  faCcMastercard,
  faCommentAltSmile,
  faEuroSign,
  faFacebookSquare,
  faFile,
  faInfoCircle,
  faLayerPlus,
  faLockAlt,
  faMinus,
  faMinusCircle,
  faPhone,
  faPlus,
  faPlusCircle,
  faQuestionSquare,
  faShoppingCart,
  faSignOut,
  faSlidersH,
  faSort,
  faSpinnerThird,
  faStar,
  faStarHalfAlt,
  faSyncAlt,
  faTrashAlt,
  faTwitterSquare,
  faUserAlt,
  faUserCircle,
  faCheck
)

Vue.component( 'FontAwesomeIcon', FontAwesomeIcon )

export default library
