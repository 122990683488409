<template>
  <div>
    <navbar logo="logo.svg" />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application @incompleteSent="onIncompleteSent" @sent="onSent" />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" :content="footer" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import Vue from 'vue'

export default {
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  layout: 'whitelabel',
  data () {
    return {
      name: 'Lainamarkkina',
      domain: 'lainamarkkina.fi',
      routes: [
        {
          path: '/yrityslainahakemus',
          component: Vue.extend( require( '~/whitelabels/lainamarkkina/yrityslainahakemus' ).default ),
          name: 'yrityslainahakemus',
          meta: {
            campaign: 'RLB_lab'
          }
        }
      ],
      footer: [
        {
          content: [
            'Lainamarkkina.fi',
            'Vauras Invest Oy',
            'Lempääläntie 21',
            '33820 Tampere',
            'Y-Tunnus: 3231673-9',
            'info@lainamarkkina.fi'
          ]
        },
        {
          content: [
            ''
          ]
        },
        {
          content: [
            `Lainamarkkina.fi on 100% kotimainen palvelu, joka tarjoaa maksuttoman ja puolueettoman lainatarjouksen
            kilpailutuksen kuluttajille ja yritysasiakkaille.`
          ]
        }
      ]
    }
  },
  head () {
    const desc = `Lainamarkkina.fi auttaa sinua löytämään lainan useilta eri pankeilta ja rahoituslaitoksilta.
      Laske laina sinulle sopivalla kuukausierällä.`
    const theme = '#6610f2'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/lainamarkkina/favicon.svg' ) }]
    }
  },
  mounted () {
    if ( this.$config.RELEASE_STAGE === 'production' ) {
      // Code that will run only after the
      // entire view has been rendered
      this.$nextTick( () => {
        /* eslint-disable */
          const head = document.getElementsByTagName("head")[0];
          var myScript = document.createElement('script');
          myScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-63720938-8');
          myScript.onload = function() {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
              window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', 'UA-63720938-8');
            window.gtag('config', 'G-QNKN76CQEF');
          }
          head.insertBefore(myScript, head.children[1]);

          delete window.fbq
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          window.fbq('init', '740474309986821');
          window.fbq('track', 'PageView');
          window.fbq('track', 'Lead');
          /* eslint-enable */
      } )
    }
  },
  methods: {
    onIncompleteSent () {
      window.fbq( 'track', 'rl_basicdata_saved' )
      window.gtag( 'event', 'rl_basicdata_saved' )
    },
    onSent () {
      window.fbq( 'track', 'application_sent' )
      window.gtag( 'event', 'application_sent' )
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";
$wl-primary: rgb(0,202,142);
$wl-secondary: #fff;
$wl-body-background: $wl-primary;
$wl-body-background: $wl-primary;
$wl-navbar-background: $wl-secondary;
$wl-body-background: url('~assets/whitelabels/lainamarkkina/background.jpg');
$wl-navbar-item-color: white;
$wl-navbar-logo-height: 100%;
$wl-button-background: $wl-primary;
$wl-footer-background: #06121f;
$wl-footer-color: white;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.lainamarkkina {
  @include whitelabel;
  nav.navbar {
    padding: 0rem 1rem;
    .navbar-brand {
      a {
        padding: 0;
        img {
          max-height: 5rem;
        }
      }
    }
  }
}
</style>
