<template>
  <div>
    <navbar />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  layout: 'whitelabel',
  data () {
    return {
      name: 'Lainapalvelusi',
      domain: 'lainapalvelusi.fi'
    }
  },
  head () {
    const desc = `Lainapalvelusi auttaa sinua löytämään lainan useilta eri pankeilta ja rahoituslaitoksilta. Laske laina
      sinulle sopivalla kuukausierällä.`
    const theme = '#6610f2'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/lainapalvelusi/favicon.png' ) }]
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #28827c;
$wl-secondary: #f7b733;

$wl-body-background: $wl-primary;

$wl-navbar-background: $wl-primary;
$wl-footer-background: #1c2d41;
$wl-interest-rate-bg: $wl-secondary;
$wl-button-background: $wl-secondary;
$wl-footer-color: white;
$wl-body-background: #28827c;
$wl-navbar-item-color: white;
$wl-navbar-logo-height: 3rem;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.lainapalvelusi {
  @include whitelabel;
}
</style>
