<template>
  <div class="cc-extranet">
    <nav class="navbar is-spaced is-transparent has-text-weight-bold">
      <div class="container">
        <div class="navbar-brand">
          <nuxt-link class="navbar-item" to="/cc-new">
            <logo-svg />
          </nuxt-link>
        </div>
        <div class="navbar-menu">
          <div class="navbar-end">
            <nuxt-link class="navbar-item" to="/cc-new/kuluttaja">
              Kuluttajahakemus
            </nuxt-link>
            <nuxt-link class="navbar-item" to="/cc-new/yritys">
              Yrityshakemus
            </nuxt-link>
            <a class="navbar-item" @click="logout">
              <span class="navbar-icon">
                <font-awesome-icon :icon="['fas', 'sign-out']" size="lg" fixed-width />
              </span>
              Kirjaudu ulos
            </a>
          </div>
        </div>
      </div>
    </nav>
    <section class="section cc-hero">
      <div class="container">
        <nuxt />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  head () {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex' }
      ]
    }
  },
  components: {
    logoSvg: () => import( '~/assets/img/logo.svg?inline' )
  },
  methods: {
    async logout () {
      const result = await this.$axios.$get( '/v1/cc/logout' )
      // Logout successful
      if ( result.status === 200 ) {
        this.$router.push( '/cc-new/login' )
      }
    }
  }
}
</script>

<style lang="scss">
.cc-extranet {
  .cc-hero {
    background-color: $rl-gray;
    background-image: url('~assets/img/hero-bg.jpg');
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 1000;
    min-height: 75vh;
  }

  .loan-change,
  .app-handling-notification {
    display: none;
  }
}
</style>
