<template>
  <div>
    <navbar />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp :content="footer" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'
import Vue from 'vue'

export default {
  layout: 'whitelabel',
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/whitelabels/vertaapankkeja/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'VertaaPankkeja.fi palvelusta saat lainaa heti 500 - 70.000 euroa ilman vakuuksia ja takaajia. Anna meidän kilpailuttaa pankit ja rahoitusyhtiöt puolestasi!'
    const theme = '#4e5c66'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/vertaapankkeja/favicon.png' ) }]
    }
  },
  data () {
    return {
      name: 'VertaaPankkeja',
      domain: 'vertaapankkeja.fi',
      footer: [
        {
          title: 'Yhteystiedot',
          content: [
            'VertaaPankkeja.fi | WebCube Oy',
            'Taitotie 1',
            '63300 Alavus',
            'Suomi – Finland',
            'Y-tunnus: 2658911-6'
          ]
        },
        {
          title: 'Asiakaspalvelu',
          content: [
            'Asiakaspalvelumme palvelee vain sähköpostitse: asiakaspalvelu@vertaapankkeja.fi'
          ]
        }
      ],
      hasServiceDescription: true,
      routes: [
        {
          path: '/palvelukuvaus',
          component: Vue.extend( require( '~/whitelabels/vertaapankkeja/palvelukuvaus' ).default ),
          name: 'palvelukuvaus'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #4e5c66;

$wl-navbar-background: white;
$wl-navbar-height: 8.75rem;
$wl-navbar-logo-height: 100%;
$wl-navbar-border-bottom: 1px solid #dddddd;

$wl-footer-background: white;
$wl-footer-link-title-color: #36444e;
$wl-footer-link-color: #616161;
$wl-footer-border-top: 1px solid #dddddd;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.vertaapankkeja {
  @include whitelabel;
}
</style>
