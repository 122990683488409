<template>
  <div>
    <navbar logo="false" text="Lainaa24<span>.fi</span>" />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  layout: 'whitelabel',
  data () {
    return {
      name: 'Lainaa24',
      domain: 'lainaa24.fi'
    }
  },
  head () {
    const desc = `Lainaa24 auttaa sinua löytämään lainan useilta eri pankeilta ja rahoituslaitoksilta. Laske laina
      sinulle sopivalla kuukausierällä.`
    const theme = '#6610f2'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/lainaa24/favicon.png' ) },
        { rel: 'stylesheet', type: 'text/css', href: 'https://fonts.googleapis.com/css?family=Exo:400,600' }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #3080bf;
$wl-secondary: #f0f8ff;

$wl-body-background: $wl-secondary;

$wl-navbar-background: $wl-secondary;
$wl-navbar-item-color: white;
$wl-navbar-logo-height: 3rem;

$wl-button-background: #FF6700;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.lainaa24 {
  @include whitelabel;

  .logo-text {
    font-family: Exo, sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: $wl-primary;
    span {
        color: #4e4f54;
    }
  }
}
</style>
