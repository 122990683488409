import { ActionTree, MutationTree } from 'vuex'
import type { NuxtCookies } from 'cookie-universal-nuxt'
import cookie from 'cookie' // can also be accessed via app.$cookies.nodeCookies but imported because of typescript
import type { Context } from '@nuxt/types'
import type { AuthState } from './auth'
import type { LoanApplicationState } from './loanApplication'
import whitelabelCheck from './../utils/Whitelabel/dataChecks'
import affiliate from '~/utils/affiliate'

export const state = () => ( {
  onload: {},
  ip: null
} )

type BaseSate = ReturnType<typeof state>
export interface RootState extends BaseSate {
  auth: AuthState
  loanApplication: LoanApplicationState
  whitelabel?: any
}

export const mutations: MutationTree<RootState> = {
  ONLOAD ( state, data = {} ) {
    state.onload = data
  },
  SET_IP ( state, ip = null ) {
    state.ip = ip
  }
}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit ( { state, commit, dispatch }, { $axios, app, redirect, req, query, route }: Context ) {
    // Get form data from POST
    await whitelabelCheck( state, commit, dispatch, arguments[1] as Context )
    // Typehinting cookies
    const cookies: NuxtCookies = app.$cookies
    // Parse custom campaign parameters
    if ( route.meta ) {
      route.meta.forEach( ( meta: any ) => {
        if ( meta.campaign ) {
          cookies.set( 'RL_arrive', meta.campaign )
        }
      } )
    }
    // Set affiliate cookies
    affiliate( query, cookies )
    // Get current cookies
    const reqCookies = cookies.getAll()
    const resCookies = cookies.getAll( { fromRes: true } )
    const allCookies = Object.assign( reqCookies, resCookies )
    let cookieString = ''
    Object.keys( allCookies ).forEach( ( servercookie ) => {
      const parsedCookie = cookie.serialize( servercookie, allCookies[servercookie as any] )
      cookieString += `${parsedCookie};`
    } )
    // Run onload
    try {
      const result = await $axios.get( '/v1/', {
        params: {
          action: 'onload',
          ...query
        },
        headers: {
          Cookie: cookieString,
          host: req.headers.host
        }
      } )
      commit( 'ONLOAD', result.data )
      if ( result.headers.location ) {
        redirect( result.headers.location )
      }
      // Parse cookies from axios
      if ( result.headers['set-cookie'] ) {
        const axiosCookies = result.headers['set-cookie'] // [ '<name>=<value>; opt=<opt>; opt=<opt>' ]
          .map(
            ( axiosCookie: string ) => axiosCookie.split( ';' )[0] // <name>=<value>
          ).join( ';' ) // <name0>=<value0>;<name1>=<value1>
        const parsed = Object.assign( allCookies, cookie.parse( axiosCookies ) ) // Returns object { name: value }
        const converted = Object.keys( parsed )
          .filter( c => c !== 'vuex' )// Remove vuex cookie
          .map( ( c ) => {
            return { name: c, value: parsed[c] }
          } )
        cookies.setAll( converted )
      }
    }
    catch ( error ) {
      app.$logger.error( error )
    }
  },
  async resetIncomplete ( { commit }, data ) {
    const cleared = Object.assign( {}, data )
    delete cleared.incomplete
    await commit( 'ONLOAD', cleared )
  }
}
