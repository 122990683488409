import Vue from 'vue'

Vue.directive( 'uppercase', ( el, binding, vnode ) => {
  const inputs = el.getElementsByTagName( 'input' )
  if ( inputs.length ) {
    const input = inputs[0]
    const upper = input.value.toUpperCase()
    // Update only when not uppercase
    if ( input.value !== upper ) {
      vnode.componentInstance.updateValue( upper )
    }
  }
} )
