export const state = () => ( {
  applicant: {},
  loanType: 'consumer',
  hasBothTypes: false,
  coapplicant: null,
  applied: null
})

export const mutations = {
  SET_APPLICANT ( state, applicant ) {
    state.applicant = applicant
  },
  SET_COAPPLICANT ( state, coapplicant ) {
    state.coapplicant = coapplicant
  },
  SET_TYPE ( state, value ) {
    if ( ['consumer', 'business'].includes( value ) ) {
      state.loanType = value
    }
    else {
      throw new Error( 'Invalid loan type' )
    }
  },
  SET_BOTHTYPES ( state, value ) {
    state.hasBothTypes = value
  },
  SET_APPLIED ( state, value ) {
    state.applied = value
  }
}

export const getters = {
  fullName: ( state ) => `${state.applicant.etunimi} ${state.applicant.sukunimi}`
}
