export function load () {
  if ( this.app.$config.RELEASE_STAGE !== 'production' ) {
    const clone = ( ...args ) => console.log( 'Adform:', ...args )
    window.adf = clone
    window.adf.ClickTrack = clone
    return
  }
  /* eslint-disable */
  window._adftrack = { pm: 699319 };
  (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://track.adform.net/serving/scripts/trackpoint/async/'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })()
  /* eslint-enable */
}

export function onApplicationSent ( _form ) {
  if ( typeof window.adf !== 'undefined' ) {
    try {
      window.adf.ClickTrack( this, 699319, 'rahalaitos.fi/|Thank You', {} )
      return true
    }
    catch ( error ) {
      if ( ['Cannot read property \'ClickTrack\' of undefined',
        'window.adf is undefined',
        'undefined is not an object (evaluating \'window.adf.ClickTrack\')',
        'Unable to get property \'ClickTrack\' of undefined or null reference',
        'Määrittämättömän tai tyhjän viittauksen ominaisuutta ClickTrack ei voi noutaa'].includes( error.message )
      ) {
        // Ignore error, probably adblock
        return
      }
      this.app.$logger.error( error )
    }
  }
  return false
}
