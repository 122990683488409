<template>
  <div>
    <navbar />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application @sent="loadThankYouPixels" />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  layout: 'whitelabel',
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  head () {
    const desc = 'Eturahoitus.fi on lainojen kilpailutukseen erikoistunut palvelu. Vain yhdellä lainahakemuksella voit saada lainatarjouksen useilta eri rahoituslaitoksilta.'
    const theme = '#43b13a'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/eturahoitus/favicon.png' ) }]
    }
  },
  data () {
    return {
      name: 'Eturahoitus',
      domain: 'eturahoitus.fi'
    }
  },
  mounted () {
    if ( this.$config.RELEASE_STAGE === 'production' ) {
      // Code that will run only after the
      // entire view has been rendered
      this.$nextTick( () => {
        /* eslint-disable */
        window._tfa = window._tfa || [];
        window._tfa.push({ notify: 'event', name: 'page_view', id: 1176366 });
        !function (t, f, a, x) {
          if (!document.getElementById(x)) {
            t.async = 1; t.src = a; t.id = x; f.parentNode.insertBefore(t, f);
          }
        }(document.createElement('script'),
          document.getElementsByTagName('script')[0],
          '//cdn.taboola.com/libtrc/unip/1176366/tfa.js',
          'tb_tfa_script');
          /* eslint-enable */
      } )
    }
  },
  methods: {
    loadThankYouPixels () {
      if ( this.$config.RELEASE_STAGE === 'production' ) {
        const trackImg = document.createElement( 'img' )
        trackImg.src = 'https://nordaffiliates.go2cloud.org/aff_l?offer_id=324'
        // Taboola script
        if ( typeof window._tfa === 'function' ) {
          window._tfa.push( { notify: 'event', name: 'lead', id: 1176366 } )
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #43b13a;

$wl-navbar-logo-height: 3.5rem;
$wl-navbar-border-bottom: 1px solid rgba(0, 0, 0, 0.1);

$wl-footer-color: white;
$wl-footer-background: #222;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.eturahoitus {
  @include whitelabel;
}
</style>
