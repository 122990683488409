<template>
  <div>
    <section v-if="$route.path === '/yrityslainahakemus'" class="section is-small">
      <div class="container">
        <loan-application />
      </div>
    </section>
  </div>
</template>

<script>

export default {
  components: {
    loanApplication: () => import( '~/components/businessApplication' )
  }
}
</script>
