<!--
  This page is used dynamically for all the other thousand sub-pages.
  You can find the logic from nuxt.config.js router.extendRoutes().
-->
<template>
  <div class="content">
    <breadcrumb
      :path="{'Yrityslainat': '/yrityslainat', [`Yrityslainaa ${formatedAmount} euroa`]: ''}"
    />
    <template v-if="amount <= 250000">
      <div class="subpage_floating">
        <img :src="require(`~/assets/img/subpage/yrityslainaa-5000-euroa-2.jpg`)" :alt="this.$route.path.substring(1)">
        <sliders
          go-to-url="/yrityslainat"
          :business-loan="true"
          :luottoraja="amount"
          :maksuaika="6"
          class="is-hidden-touch"
        />
      </div>
      <h1>Yrityslainaa {{ formatedAmount }} € nopeasti</h1>
      <p>
        Tarvitseeko yrityksesi {{ formatedAmount }} euroa lainaa nopeasti kuluihin tai
        investointeihin? Rahalaitos kilpailuttaa yrityslainat muutamassa
        minuutissa Suomen suurimmilla verkossa toimivilla yrityslainan
        tarjoajilla. Rahalaitoksen kautta voit hakea yrityslainaa tai rahoitusta
        1 000 eurosta aina 8 000 000 euroon asti. Kauttamme kilpailutat
        {{ formatedAmount }} euron yrityslainan helposti yli kymmenellä lainantarjoajalla
        ja löydät yrityksesi tarpeisiin sopivan lainan.
      </p>
      <p>
        Tarvitseepa yrityksesi rahoitusta sitten investointeihin tai satunnaisten
        kulujen kattamiseen, voit hakea Rahalaitoksen kumppaneilta {{ formatedAmount }}
        euroa yrityslainaa ilman reaalivakuuksia. Kilpailutus on täysin ilmainen
        eikä sido sinua mihinkään. Täytä vain hakemus niin me etsimme sinulle
        edullisimman lainatarjouksen!
      </p>
      <h2>
        {{ formatedAmount }} euron yrityslainan hinta
      </h2>
      <p>
        Lainantarjoajien antamat yrityslainatarjoukset ovat aina yksilöllisiä ja
        perustuvat lainanhakijan ja yrityksen tietojen pohjalta tehtävään
        riskiarvioon. Alla olevasta taulukosta näet suuntaa antavasti millaiseen
        kuukausierään {{ formatedAmount }} euron yrityslainan kohdalla on hyvä varautua.
        Pääset suoraan hakulomakkeella valitsemalla haluamasi laina-ajan taulukosta.
      </p>
      <div class="table-container is-mobile" style="max-width: 45rem;">
        <table class="table is-bordered is-fullwidth">
          <thead>
            <tr>
              <th class="numeric">Lainan suuruus</th>
              <th class="numeric">Laina-aika</th>
              <th class="numeric">Kuukausierä</th>
              <th class="numeric">Linkki</th>
            </tr>
          </thead>
          <tbody v-for="(value, index) in calculations" :key="index">
            <tr v-if="calculations[index + 1]">
              <td data-title="Lainan suuruus">{{ formatedAmount }} €</td>
              <td data-title="Laina-aika">{{ index + 1 }} kk</td>
              <td data-title="Kuukausierä">{{ calculations[index + 1] }} € /kk</td>
              <td data-title="Linkki">
                <nuxt-link to="/yrityslainat">Hae yrityslainaa</nuxt-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else>
      <div class="subpage_floating">
        <img
          :src="require(`~/assets/img/subpage/yrityslainaa-5000-euroa.jpg`)"
          :alt="this.$route.path.substring(1)"
        >
      </div>
      <h1>Yrityslainaa {{ formatedAmount }} € luotettavasti</h1>
      <p>
        Tarvitseeko yrityksesi {{ formatedAmount }} euroa rahoitusta investointeihin tai
        liiketoiminnan kuluihin? Rahalaitos kilpailuttaa yrityslainat Suomen
        suurimmilla verkossa toimivilla yrityslainan tarjoajilla. Rahalaitoksen
        kautta voit hakea yrityslainaa tai rahoitusta 1 000 eurosta aina 8 000 000
        euroon asti. Kauttamme kilpailutat {{ formatedAmount }} euron yrityslainan helposti
        ja löydät yrityksesi tarpeisiin sopivan lainan.
      </p>
      <p>
        Tarvitseepa yrityksesi rahoitusta sitten investointeihin tai satunnaisten
        kulujen kattamiseen, voit hakea Rahalaitoksen kumppaneilta {{ formatedAmount }}
        euroa yrityslainaa kätevästi. Kilpailutus on täysin ilmainen eikä sido
        sinua mihinkään. Täytä vain hakemus niin me etsimme sinulle yrityksesi
        tarpeisiin sopivan lainatarjouksen!
      </p>
      <p>
        <nuxt-link to="/yrityslainat">Täytä hakemus tästä</nuxt-link>
      </p>
      <h2>
        Yritykselle rahoitusta kahdeksaan miljoonaan saakka
      </h2>
      <p>
        Kun yritys tarvitsee rahoitusta satoja tuhansia tai miljoonia euroja on prosessi
        perinteisten pankkien kanssa usein hidas ja kankea. Rahalaitoksen kumppaneiden
        kautta asia hoituu nopeasti ja sujuvasti. Silti suuremmat rahoitustarpeet käsitellään
        yksilöllisesti yrityksen tilanne huomioiden. Jos yrityksesi tarvitsee {{ formatedAmount }}
        euroa yritysrahoitusta, kannattaa ehdottomasti katsoa millaista rahoitusta
        kumppanimme pystyvät tarjoamaan.
      </p>
      <br>
      <span class="miniform-header"> Lainan kilpailutus 8 000 000 € asti </span>
      <mini-form
        :business-loan="true"
        :vauraus-loan="true"
        go-to-url="/yrityksen-rahoitus"
      />
    </template>
  </div>
</template>

<script>
import { calculate as loanCalculator } from '~/utils/loanDetail'

export default {
  name: 'YrityslainaaXXEuroa',
  components: {
    sliders: () => import( '~/components/subpage/sliders' ),
    miniForm: () => import( '~/components/subpage/miniform' ),
    breadcrumb: () => import( '~/components/breadcrumb' )
  },
  layout: 'businessSubpage',
  data () {
    return {
      calculations: []
    }
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Kasvurahoitusta',
        subtitle: 'Lainaa kasvuun ja yllättäviin tilanteisiin',
        link: '/talous/nain-selviat-kasvun-haasteista-ja-yllattavista-tilanteista'
      },
      {
        title: 'Pankista vai netistä?',
        subtitle: 'Vakuudeton yrityslaina netistä vai perinteinen pankkilaina',
        link: '/talous/hakeako-yrityslaina-perinteisesti-pankista-vai-nopeasti-ilman-vakuuksia'
      },
      {
        title: 'Korot ja kulut',
        subtitle: 'Miten yrityslainoja on järkevintä vertailla?',
        link: '/talous/miten-yrityslainoja-on-jarkevin-ja-helpoin-vertailla'
      }
    ] )
  },
  head () {
    return {
      title: `Yrityslainaa ${this.amount} euroa ilman vakuuksia nopeasti`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Etsitkö ${this.amount} lainaa? Kilpailuta eri lainapaikat ilmaiseksi meillä - voit saada jopa 25
            lainatarjousta! Kokeile heti!`
        }
      ]
    }
  },
  computed: {
    amount () {
      return parseInt( this.$route.name.split( '-' )[1] )
    },
    formatedAmount () {
      let amount = Math.round( this.amount / 100 ) * 100
      if ( amount > 999 ) {
        amount = amount.toString().replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ' )
      }
      return amount
    }
  },
  mounted () {
    /**
     * 1 - 24 with 1 month interval
     * 24 - 36 with 2 month interval
     */
    for ( let index = 0; index < 36; index++ ) {
      if ( index > 23 ) {
        index++
      }
      this.calculations[index + 1] = loanCalculator( this.amount, index + 1, 'business' ).monthly
    }
  }
}
</script>
