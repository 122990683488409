<template>
  <div class="container">
    <section class="section">
      <div class="content">
        <h1 class="title">Palvelukuvaus</h1>
        <p>
          VertaaPankkeja.fi on WebCube Oy:n ylläpitämä kotimainen palvelu, joka etsii Sinulle sopivimman lainan
          useiden lainanmyöntäjien joukosta. Palvelun avulla lainanhakija saa maksutta selville millaista lainaa sekä
          millaisin ehdoin on mahdollista saada. Lainahakemuksesi lähetetään eteenpäin lainanvälittäjälle, joka
          kilpailuttaa hakemuksesi useilla pankeilla ja rahoitusyhtiöillä. VertaaPankkeja.fi markkinoi kotimaisen
          Rahalaitos.fi operoimaa luvanvaraista lainanvälityspalvelua. Autamme sinua lainanhaussa vertailemalla eri
          pankkeja ja rahoitusyhtiöitä. Voit myös yhdistää palvelussa nykyiset lainasi yhdeksi edulliseksi lainaksi.
        </p>
        <p>
          Turvallisuus on tärkeä osa palveluamme. Tästä syystä kaikki lainahakemuksella antamasi tiedot pysyvät
          salatun https -yhteyden (SSL) takana koko lainaprosessin ajan. Käyttämällä VertaaPankkeja.fi palvelua annat
          meille luvan olla sinuun yhteydessä sähköpostien ja tekstiviestien välityksellä koskien lainatarjouksia ja –
          uusia palveluita.
        </p>
        <p>Palvelu on maksuton eikä sido sinua mihinkään</p>
      </div>
      <button class="button is-primary" @click="closeWindow">Sulje palvelukuvaus</button>
    </section>
  </div>
</template>

<script>
export default {
  layout: 'whitelabel',
  props: {
    siteName: {
      type: String,
      required: true
    }
  },
  head () {
    return {
      title: `${this.siteName} | Palvelukuvaus`,
      meta: [
        { hid: 'description', name: 'description', content: `${this.siteName} palvelukuvaus.` }
      ]
    }
  },
  methods: {
    closeWindow () {
      window.close()
    }
  }
}
</script>
