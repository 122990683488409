export function load () {
  /**
   * Load the script when conditions match
   *   - is production
   *   - does have arrive
   *   - arrive starts with RL_rp
   */
  if (
    this.app.$config.RELEASE_STAGE === 'production' &&
    Object.prototype.hasOwnProperty.call( this.route.query, 'arrive' ) &&
    ( this.route.query.arrive.substring( 0, 5 ) === 'RL_rp' || this.route.query.arrive.substring( 0, 5 ) === 'RL_RP' )
  ) {
    /* eslint-disable */
    (function(r,e,a,d){r.__rpta = r.__rpta||[];r.__rpta.push('id',e);a=document.createElement('script');a.type='text/javascript';a.src='//app.readpeak.com/js/rpa.js';a.async=1;d=document.getElementsByTagName('script')[0];d.parentNode.insertBefore(a,d);})(window,225198209253925);
    /* eslint-enable */
  }
  // Do not load script but simulate the array
  else {
    window.__rpta = []
  }
}

export function onIncompleteSent ( _form ) {
  if ( Array.isArray( window.__rpta ) ) {
    try {
      window.__rpta.push( 'event', 'incomplete_sent' )
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
    return false
  }
}

export function onApplicationSent ( _form ) {
  if ( Array.isArray( window.__rpta ) ) {
    try {
      window.__rpta.push( 'event', 'application_sent' )
      return true
    }
    catch ( error ) {
      this.app.$logger.error( error )
    }
    return false
  }
}
