<template>
  <div>
    <navbar logo="false" />
    <nuxt-child :site-name="name" />
    <section v-if="$route.path === '/'" class="section is-small">
      <div class="container">
        <loan-application @sent="loadThankYouPixels" />
      </div>
    </section>
    <lazy-hydrate ssr-only>
      <footer-comp logo="false" />
    </lazy-hydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    footerComp: () => import( '~/whitelabels/components/footer' ),
    loanApplication: () => import( '~/components/loanApplication' ),
    navbar: () => import( '~/whitelabels/components/navbar' )
  },
  layout: 'whitelabel',
  data () {
    return {
      name: 'Euro24',
      domain: 'euro24.fi'
    }
  },
  head () {
    const desc = `Taskulainan hakeminen on helppoa ja vaivatonta. Valitse sopiva lainasumma Tunnistaudu
      verkkopankkitunnuksilla Täytä hakemus - lainapäätöksen saat heti!`
    const theme = '#f3891e'
    return {
      title: `${this.name} | Lainahakemus`,
      meta: [
        { hid: 'description', name: 'description', content: desc },
        { hid: 'og:title', name: 'og:title', property: 'og:title', content: this.name },
        { hid: 'og:site_name', name: 'og:site_name', property: 'og:site_name', content: this.name },
        { hid: 'og:description', name: 'og:description', property: 'og:description', content: desc },
        { hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: this.name },
        { hid: 'theme-color', name: 'theme-color', content: theme }
      ],
      link: [{ rel: 'icon', type: 'image/x-icon', href: require( '~/assets/whitelabels/taskulaina/favicon.png' ) }]
    }
  },
  mounted () {
    if ( this.$config.RELEASE_STAGE === 'production' ) {
      // Code that will run only after the
      // entire view has been rendered
      this.$nextTick( () => {
        /* eslint-disable */
          const head = document.getElementsByTagName("head")[0];
          var myScript = document.createElement('script');
          myScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-26069897-1');
          myScript.onload = function() {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
              window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());
            window.gtag('config', 'UA-26069897-1');
          }
          head.insertBefore(myScript, head.children[1]);
          /* eslint-enable */
      } )
    }
  },
  methods: {
    loadThankYouPixels ( formData ) {
      if ( this.$config.RELEASE_STAGE === 'production' && window.gtag ) {
        window.gtag( 'event', 'thankyou', {
          event_action: 'success',
          event_category: 'form',
          event_label: 'rl form',
          event_value: formData.amount
        } )
      }
    }
  }
}
</script>

<style lang="scss">
@import "~assets/whitelabels/scss/_variables.scss";

$wl-primary: #f3891e;
$wl-body-background: url("https://euro24.fi/wp-content/uploads/2020/10/pari-puistossa-e1601980756339.jpg");
$wl-navbar-item-color: white;
$wl-navbar-background: #f3891e;

$wl-app-background: #fafafa;

$wl-button-background: #f3891e;

$wl-footer-color: white;
$wl-footer-background: #007fa6;

@import "~assets/whitelabels/scss/styles.scss";

.whitelabel.euro24 {
  @include whitelabel;

  .navbar-item {
    text-transform: uppercase;
  }
}
</style>
