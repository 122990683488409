export const state = () => ( {
  pages: [
    {
      title: 'Yhdistä lainasi',
      subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
      link: '/yhdista-lainat'
    },
    {
      title: 'Lainaa ilman takaajia',
      subtitle: 'Haaveista totta nopeasti ja helposti',
      link: '/lainaa-ilman-takaajia'
    },
    {
      title: 'Kilpailuta pohjoismaiset pankit',
      subtitle: 'Vertaile ja katso mistä saat edullisimman lainan',
      link: '/talous/suomalainen-ruotsalainen-vai-norjalainen-pankki',
      image: '/cms/uploads/large_photo_1538332576228_eb5b4c4de6f5_3bdf11ac3a.jpg'
    }
  ]
} )

export const mutations = {
  SET_PAGES ( state, data ) {
    state.pages = data
  }
}

export const actions = {
  async setPages ( { commit }, data ) {
    await commit( 'SET_PAGES', data )
  }
}
